import { RouteConfig } from "vue-router";
import RatingsComponent from "@/views/ratings/ratings.vue";
import { Roles } from "@/views/authenticate/authenticate.types";

export const RATINGS_ROUTES: RouteConfig[] = [
  {
    path: "/ratings",
    name: "Ratings",
    component: RatingsComponent,
    meta: {
      roles: [Roles.PracticeUser, Roles.OfficeUser],
      nav: true,
      navTitle: "Shift Rating Center",
      navIcon: "fa-star"
    }
  }
];