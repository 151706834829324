import { RouteConfig } from "vue-router";
import OfficeProfilesComponent from "@/views/office-profiles/office-profiles.vue";
import OfficeProfilesEditComponent from "@/views/office-profiles/office-profiles-edit.vue";
import { Roles } from "@/views/authenticate/authenticate.types";

export const OFFICE_PROFILES_ROUTES: RouteConfig[] = [
  {
    path: "/office-profiles",
    name: "OfficeProfiles",
    component: OfficeProfilesComponent,
    meta: {
      roles: [Roles.PracticeUser, Roles.OfficeUser],
      nav: true,
      navTitle: "Office Profiles",
      navIcon: "fa-info"
    }
  },
  {
    path: "/office-profiles-edit",
    name: "OfficeProfilesEdit",
    component: OfficeProfilesEditComponent,
    meta: {
      roles: [Roles.PracticeUser, Roles.OfficeUser],
    }
  }
];