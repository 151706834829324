import { Component, Vue } from "vue-property-decorator";
import { mapState } from "vuex";
import { 
  PracticeOfficeNotification
} from "./notifications.types";

@Component({
  computed: {
    ...mapState("Notifications", [
      "notifications"
    ])
  }
})
export default class NotificationsMixin extends Vue {
  notifications!: PracticeOfficeNotification[];
}