import { render, staticRenderFns } from "./filter-popover.vue?vue&type=template&id=a2c6d4ba&lang=pug"
import script from "./filter-popover.vue?vue&type=script&lang=ts"
export * from "./filter-popover.vue?vue&type=script&lang=ts"
import style0 from "./filter-popover.vue?vue&type=style&index=0&id=a2c6d4ba&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports