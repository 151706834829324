
import { Component, Mixins } from "vue-property-decorator";
import AuthenticateMixin from "./authenticate.mixin";
import { ErrorBag, Field } from "vee-validate";
import { 
    NewPasswordDto,
} from "./authenticate.types";
import { mapState } from "vuex";

@Component({
    computed: {
    ...mapState("Authenticate", ["errorMessage"])
    }
})
export default class NewPassword extends Mixins(AuthenticateMixin) {
    passwordDto: NewPasswordDto = new NewPasswordDto();
    passwordShow = false;
    confirmShow = false;
    loading = false;

    veeFields!: Field;
    veeErrors!: ErrorBag;
    errorMessage!: string;

    viewPassword() {
        this.passwordShow = !this.passwordShow;
    }

    viewConfirm() {
        this.confirmShow = !this.confirmShow;
    }

    validateState(ref: keyof Field) {
        if (
            this.veeFields[ref] &&
            (this.veeFields[ref].dirty || 
            this.veeFields[ref].validated)
        ) {
            return !this.veeErrors.has(ref);
        }
        return null;
    }

    mounted() {
        this.passwordDto =  {
            token: this.$route.params.token,
            email: this.$route.params.email,
            password: "",
            confirm: ""
        }
    }

    closeModal() {
        this.$router.push("/login");
    }

    async authenticate() {
        this.$validator.validateAll().then(async (isValid: boolean) => {
            if (isValid) {
                this.loading = true;
                var result = await this.$store.dispatch("Authenticate/resetPassword", this.passwordDto);
                if (result) {
                    alert(result);
                    this.closeModal();
                }
                this.loading = false;
            }   
        });
    }
}
