
import { Component, Prop, Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';
import { JobPost, JobPostStatus, PracticeOfficeModel } from '../postings.types';
import { PayrollType, EmployeeType } from '@/views/office-profiles/office-profiles.types';

@Component({
  computed: {
    ...mapState("Postings", 
    [
      "selectedOffice",
    ])
  }
})
export default class ShiftDetailModal extends Vue {
  @Prop() jobPost?: JobPost;
  @Prop() isDelete!: boolean;
  selectedOffice!: PracticeOfficeModel;

  PayrollType = PayrollType;

  getEmployeeType(employeeType: EmployeeType): string {
    switch(employeeType) {
      case EmployeeType.WTwo:
        return 'W2';
      case EmployeeType.OneZeroNineNine:
        return '1099'
    }
  }

  getPayrollType(payrollType: PayrollType): string {
    switch(payrollType) {
      case PayrollType.EndOfDay:
        return 'End Of Day';
      case PayrollType.DirectDeposit:
        return 'Direct Deposit';
      default:
        return PayrollType[payrollType];
    }
  }

  closeModal(): void {
    this.$bvModal.hide('shift-detail-modal');
  }

  async deleteShift(): Promise<void> {
    if (!this.jobPost) {
      return;
    }

    this.closeModal();

    this.$emit("loading", true);
    await this.$store.dispatch("Postings/setJobPostGroupStatus", {
      jobPostGroupId: this.jobPost.id,
      status: JobPostStatus.Deleted
    });
    await this.$store.dispatch("Postings/fetchJobPostings", this.selectedOffice.practiceOfficeId);
    this.$emit("loading", false);
  }

  formatDate(dateString: string): string {
    const date = new Date(dateString);
    const options = { month: 'long' } as Intl.DateTimeFormatOptions;
    const day = date.getDate();
    let daySuffix;

    switch (day) {
        case 1:
        case 21:
        case 31:
            daySuffix = 'st';
            break;
        case 2:
        case 22:
            daySuffix = 'nd';
            break;
        case 3:
        case 23:
            daySuffix = 'rd';
            break;
        default:
            daySuffix = 'th';
    }

    return `${date.toLocaleDateString('en-US', options)} ${day}${daySuffix}, ${date.getFullYear()}`;
  }
}
