export class EmployeeDetailsModel {
    firstName: string = "";
    lastName: string = "";

    phoneNumber: string = "";
    email: string = "";
    rating: number = 0;
    positions: string = "";
    isFavorite: boolean = false;
    profileImageUrl: string = "";

    shiftHistory: Array<UserShiftRecord> = [];
    certificates: Array<UserCertificate> = [];
}

export class UserShiftRecord {
    entryDate: Date | null = null;
    office: string = "";
    rating: number = 0;
    comments: string = "";
}

export class UserCertificate {
    name: string = "";
    certificateId: number = 0;
    hasImage: boolean = false;
}


export class EmployeeKey {
    userId: number = 0;
    practiceId: number = 0;
}