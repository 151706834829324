
import { Component, Mixins } from "vue-property-decorator";
import { HourFormDto, InformationFormDto, WizardStep } from "../wizard.types";
import WizardMixin from "../wizard.mixin";
import { mapState } from "vuex";

@Component({
  computed: {
    ...mapState('Wizard', {
      forms: 'hourForms',
      infoForm: 'infoForm'
    })
  }
})
export default class WizardHoursComponent extends Mixins(WizardMixin) {
  forms!: HourFormDto[];
  infoForm!: InformationFormDto;
  WizardStep = WizardStep;

  finishForm(): void {
    this.$validator.validateAll().then((isValid: boolean) => {
      if (isValid) {
        this.$validator.reset();
        this.next(WizardStep.Rates);
      }
    });
  }
}
