import httpAuth from "@/common/http/http-common";
import { Position } from "@/views/postings/postings.types";
import { IWizardFormDto } from "./wizard.types";

class WizardService {
  async getPositions(): Promise<Position[]> {
    const { data } = await httpAuth().get(
      `Postings/GetPositions`
    );
    return data;
  }

  async validateUsername(username: string): Promise<void> {
    const { data } = await httpAuth().get(
      `Wizard/ValidateUsername?username=${username}`
    );
    return data;
  }

  async finish(form: IWizardFormDto): Promise<void> {
    const { data } = await httpAuth().post(
      'Wizard/Finish',
      form
    );
    return data;
  }
}

export default new WizardService();