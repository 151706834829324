import { VuexModule, Module, Action } from "vuex-module-decorators";
import { UserFormDto } from "@/common/types";
import UserService from "@/services/UserService";
import { updateField } from "vuex-map-fields";


@Module({ namespaced: true })
class User extends VuexModule {
  userForm = new UserFormDto();

  @Action({ rawError: true })
  async getForm(id: number): Promise<void> {
    const userForm = await UserService.getForm(id);

    this.context.commit("updateField", {
      path: "userForm",
      value: userForm
    });
  }

  @Action({ rawError: true })
  async update(form: UserFormDto): Promise<void> {
    await UserService.update(form);
  }
}

User.mutations = { updateField };

export default User;
