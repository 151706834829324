import { RouteConfig } from "vue-router";
import NotificationsComponent from "@/views/notifications/notifications.vue";
import { Roles } from "@/views/authenticate/authenticate.types";

export const NOTIFICATIONS_ROUTES: RouteConfig[] = [
  {
    path: "/notifications",
    name: "Notifications",
    component: NotificationsComponent,
    meta: {
      roles: [Roles.PracticeUser, Roles.OfficeUser],
      nav: true,
      navTitle: "Notifications",
      navIcon: "fa-bell"
    }
  }
];