import { getField, updateField } from "vuex-map-fields";
import { VuexModule, Module, Action } from "vuex-module-decorators";
import { IVacancyDto, VacancyDetailsDto, VacancyFormDataDto, VacancyFormDto } from "./vacancies-types";
import VacancyService from "./vacancies-service";

@Module({ namespaced: true })
class Vacancy extends VuexModule {
  vacancies: IVacancyDto[] = [];
  vacancyForm = new VacancyFormDto();
  vacancyFormData = new VacancyFormDataDto();
  vacancyDetails = new VacancyDetailsDto();

  @Action({ rawError: true })
  resetForm(): void {
    this.context.commit("updateField", {
      path: "vacancyForm",
      value: new VacancyFormDto()
    });
  }

  @Action({ rawError: true })
  resetDetails(): void {
    this.context.commit("updateField", {
      path: "vacancyDetails",
      value: new VacancyDetailsDto()
    });
  }

  @Action({ rawError: true })
  async get(practiceId: number): Promise<void> {
    const vacancies = await VacancyService.get(practiceId);
    
    this.context.commit("updateField", {
      path: "vacancies",
      value: vacancies
    });
  }
  
  @Action({ rawError: true })
  async getForm(id: number): Promise<void> {
    const vacancyForm = await VacancyService.getForm(id);

    if (!vacancyForm.schedulerIds) {
      vacancyForm.schedulerIds = [];
    }

    this.context.commit("updateField", {
      path: "vacancyForm",
      value: vacancyForm
    });
  }

  @Action({ rawError: true })
  async getFormData(practiceId: number): Promise<void> {
    const vacancyFormData = await VacancyService.getFormData(practiceId);

    this.context.commit("updateField", {
      path: "vacancyFormData",
      value: vacancyFormData
    });
  }

  @Action({ rawError: true })
  async getDetails(data: { id: number, practiceId: number }): Promise<void> {
    const vacancyDetails = await VacancyService.getDetails(data.id, data.practiceId);

    this.context.commit("updateField", {
      path: "vacancyDetails",
      value: vacancyDetails
    });
  }

  @Action({ rawError: true })
  async delete(id: number): Promise<void> {
    await VacancyService.delete(id);
  }

  @Action({ rawError: true })
  async toggleInterest(data: { userId: number, vacancyId: number, isInterested: boolean | null }): Promise<void> {
    await VacancyService.toggleInterest(data.userId, data.vacancyId, data.isInterested);
  }

  @Action({ rawError: true })
  async toggleFill(vacancyId: number): Promise<void> {
    await VacancyService.toggleFill(vacancyId);
  }

  @Action({ rawError: true })
  async repost(vacancyId: number): Promise<void> {
    const form = await VacancyService.getForm(vacancyId);
    
    form.id = 0;

    if (!form.schedulerIds?.length) {
      form.schedulerIds = null;
    }

    await VacancyService.save(form);
  }

  @Action({ rawError: true })
  async save(form: VacancyFormDto): Promise<void> {
    if (!form.schedulerIds?.length) {
      form.schedulerIds = null;
    }

    await VacancyService.save(form);
  }
}

Vacancy.getters = { getField };
Vacancy.mutations = { updateField };

export default Vacancy;