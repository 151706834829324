
import { Component, Vue, Prop } from "vue-property-decorator";
import { ErrorBag, Field } from "vee-validate";
import { mapState } from "vuex";
import { 
  PracticeUser, 
  PracticeOfficeDropdown,
  PracticeOfficeSelect 
} from "./schedulers.types";

@Component({
  computed: {
    ...mapState("Schedulers", [
      "selectedUser", 
      "options",
      "errorMessage"
    ])
  }
})
export default class SchedulersEditUserComponent extends Vue {
  @Prop() modalShow!: boolean;
  
  options!: PracticeOfficeDropdown[];
  errorMessage!: string;
  selectedUser!: PracticeUser;
  veeFields!: Field;
  veeErrors!: ErrorBag;

  officeSelected: PracticeOfficeSelect[] = [];
  user = new PracticeUser();
  loading = false;

  mounted() {
    this.user = {...this.selectedUser};
  }
  
  validateState(ref: string) {
    if (
      this.veeFields[ref as keyof Field] &&
      (this.veeFields[ref as keyof Field].dirty || 
      this.veeFields[ref as keyof Field].validated)
    ) {
      return !this.veeErrors.has(ref);
    }
    return null;
  }

  async editUser() {
    this.$validator.validateAll().then(async (isValid: boolean) => {
      if (isValid) {
        this.loading = true;
        await this.$store.dispatch("Schedulers/upsertScheduler", {
          userId: this.user.userId,
          firstName: this.user.firstName,
          lastName: this.user.lastName,
          name: this.user.name,
          phoneNumber: this.user.phoneNumber,
          email: this.user.emailAddress,
          practiceOfficeSelect: [...this.officeSelected]
        });
        this.loading = false;
        if (!this.errorMessage) {
          this.$emit('reload');
          this.$emit('update:modalShow', false);
        }
      }
    });
  }

  onChange(value: any) {
    this.officeSelected = [];
    this.options.forEach(option => {
      if (this.selectedUser.practiceOfficeIds.includes(option.value) && !value.includes(option.value)) {
        this.officeSelected.push({
          practiceOfficeId: option.value,
          status: "removed"
        })
      } else if (!this.selectedUser.practiceOfficeIds.includes(option.value) && value.includes(option.value)) {
        this.officeSelected.push({
          practiceOfficeId: option.value,
          status: "added"
        })
      }
    });
  }

  closeModal() {
    this.$emit('update:modalShow', false);
  }
}
