
import { Component, Vue } from "vue-property-decorator";
import { ErrorBag, Field } from "vee-validate";
import { RegisterDto } from "./authenticate.types";
import { mapState } from "vuex";

@Component({
  computed: {
    ...mapState("Authenticate", ["errorMessage", "user"])
  }
})
export default class Register extends Vue {
  registerDto: RegisterDto = new RegisterDto();
  passwordShow = false;
  confirmShow = false;
  loading = false;

  veeFields!: Field;
  veeErrors!: ErrorBag;
  errorMessage!: string;

  viewPassword() {
    this.passwordShow = !this.passwordShow;
  }

  viewConfirm() {
    this.confirmShow = !this.confirmShow;
  }

  validateState(ref: keyof Field) {
    if (
      this.veeFields[ref] &&
      (this.veeFields[ref].dirty || 
      this.veeFields[ref].validated)
    ) {
      return !this.veeErrors.has(ref);
    }
    return null;
  }

  resetErrorMessage() {
    this.$store.commit("Authenticate/updateField", {
        path: "errorMessage",
        value: null
      });
  }

  authenticate() {
    this.$validator.validateAll().then(async (isValid: boolean) => {
      if (isValid) {
        this.loading = true;
        this.resetErrorMessage();
        await this.$store.dispatch("Authenticate/register", this.registerDto);
        this.loading = false;
        if (!this.errorMessage) {
          this.$router.push("/login");
        }
      }
    });
  }
}
