
import { EmployeeDetails, EmployeeKey } from '@/components/employee-details';
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { mapState } from 'vuex';
import AuthenticateMixin from '../../authenticate/authenticate.mixin';
import { IRequestedJobPost, JobPostGroupResponseStatus, PracticeOfficeModel, RequestedWorker } from '../postings.types';

@Component({
  components: {
    EmployeeDetails
  },
  computed: {
    ...mapState("Postings", 
    [
      "selectedOffice",
    ])
  }
})
export default class ShiftDetailModal extends Mixins(AuthenticateMixin) {
  @Prop() jobPost?: IRequestedJobPost;
  selectedOffice!: PracticeOfficeModel;
  selectedEmployee: RequestedWorker | null = null;
  isEmployeeDetailsVisible = false;

  get employeeKey(): EmployeeKey {
    return { userId: this.selectedEmployee?.userId ?? 0, practiceId: this.userInfo()?.practiceId ?? 0  }
  }

  showEmployeeDetailsModal(employee: RequestedWorker): void {
    this.selectedEmployee = employee;
    this.isEmployeeDetailsVisible = true;
  }
  
  async fetchJobPosts(value: { isFavorite: boolean, rating: number }): Promise<void> {
    const contextEmployee = this.jobPost?.requestedWorkers
        .find(worker => worker.userId === this.selectedEmployee?.userId);

    if (value && contextEmployee) {
      contextEmployee.isFavorite = value.isFavorite;
    }

    await this.$store.dispatch("Postings/fetchJobPostings", this.selectedOffice.practiceOfficeId);
  }

  closeModal(): void {
    this.$bvModal.hide('open-shift-request-modal');
  }

  async accept(userId: number, jobPostGroupResponseId: number): Promise<void> {
    this.closeModal();

    this.$emit("loading", true);

    await this.$store.dispatch("Postings/setJobPostGroupResponseStatus", {
      jobPostGroupResponseId,
      userId,
      status: JobPostGroupResponseStatus.Accepted
    });

    await this.$store.dispatch("Postings/fetchJobPostings", this.selectedOffice.practiceOfficeId);

    this.$emit("loading", false);
  }
}
