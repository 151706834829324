import Vue from 'vue'
import VueRouter, { Route, RouteConfig } from 'vue-router'
import { AUTHENTICATE_ROUTES } from "@/views/authenticate/routes";
import { MARKETING_ROUTES } from '@/views/marketing/routes';
import { WIZARD_ROUTES } from "@/views/wizard/routes";
import { BASE_ROUTES } from "@/views/base/routes";
import { CUSTOMER_SUPPORT_ROUTES } from '@/views/customer-support/routes';
import jwtDecode from 'jwt-decode';
import { IAccessTokenData, IUserInfo } from '@/views/authenticate/authenticate.types';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  ...BASE_ROUTES,
  ...AUTHENTICATE_ROUTES,
  ...MARKETING_ROUTES,
  ...WIZARD_ROUTES,
  ...CUSTOMER_SUPPORT_ROUTES
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to: Route, _, next) => {
  if (!to.meta?.roles && to.path !== "/") {
    next();

    return;
  }

  const token = sessionStorage.getItem('token');

  if (!token) {
    return next({ name: 'Login', replace: true });
  }

  const tokenData = jwtDecode<IAccessTokenData>(token);

  if (to.meta?.roles?.every((role: string) => role !== tokenData.role)) {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('user-info');
    sessionStorage.removeItem('support-user-info');
    sessionStorage.removeItem('support-token');
    next({ name: 'Login', replace: true });
  }
  
  if (to.name === 'Wizard') {
    const userInfo = sessionStorage.getItem('user-info');

    if (!userInfo) {
      return next({ name: 'Login', replace: true });
    }

    const userInfoData = JSON.parse(userInfo) as IUserInfo;

    if (userInfoData.isWizardComplete) {
      next({ name: 'Postings', replace: true });
    }
  }

  next();
});

export default router;
