
import { Component, Mixins } from "vue-property-decorator";
import OfficeInformationEdit from "./office-information-edit.vue";
import OfficeStaffEdit from "./office-staff-edit.vue";
import OfficeHoursEdit from "./office-hours-edit.vue";
import OfficeRatesEdit from "./office-rates-edit.vue";
import OfficePayrollEdit from "./office-payroll-edit.vue";
import OfficeProfilesMixin from "./office-profiles.mixin";

@Component({
  components: {
    OfficeInformationEdit,
    OfficeStaffEdit,
    OfficeHoursEdit,
    OfficeRatesEdit,
    OfficePayrollEdit
  }
})

export default class OfficeProfilesEditComponent extends Mixins(OfficeProfilesMixin) {
  loading = false;
  activeTab = 1;

  backToList() {
    this.$router.push("/office-profiles");
  }

  active(tab: number) {
    return (this.isEdit && tab == 1) || 
      (!this.isEdit && this.activeTab != tab);
  }

  disabled(tab: number) {
    return !this.isEdit && this.activeTab != tab;
  }
}
