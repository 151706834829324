import httpAuth from "@/common/http/http-common";
import { 
  JobPostModel,
  JobPostGroupStatusDto,
  JobPostGroupSetDto,
  PracticeOfficeUserDto,
  IPracticeOfficeJobPost,
  PracticeOfficeRate,
  PracticeOfficeModel,
  Position,
  PostShiftDetails,
  PracticeOfficeHour
} from "./postings.types";

class PostingsService {
  async getPracticeOffices(userId: number): Promise<PracticeOfficeModel[]> {
    const { data } = await httpAuth().get(
      `Postings/GetPracticeOffices?userId=${userId}`
    );
    return data;
  }

  async postShift(jobPost: JobPostModel): Promise<void> {
    const { data } = await httpAuth().post(
      `Postings/PostShift`,
      jobPost
    );
    return data;
  }

  async getPracticeOfficeJobPostings(practiceOfficeId?: number): Promise<IPracticeOfficeJobPost[]> {
    let uri = 'Postings/GetPracticeOfficeJobPostings'

    if (practiceOfficeId){
      uri += `?practiceOfficeId=${practiceOfficeId}`;
    }

    const { data } = await httpAuth().get(uri);
    return data;
  }

  async setJobPostGroupStatus(groupStatusDto: JobPostGroupStatusDto): Promise<void> {
    const { data } = await httpAuth().get(
      `Postings/SetJobPostGroupStatus?jobPostGroupId=${groupStatusDto.jobPostGroupId}&status=${groupStatusDto.status}`
    );
    return data;
  }

  async setJobPostGroupResponseStatus(jobPostGroupSetDto: JobPostGroupSetDto): Promise<void> {
    const { data } = await httpAuth().post(
      `Postings/SetJobPostGroupResponseStatus`,
      jobPostGroupSetDto
    );
    return data;
  }

  async getPracticeOfficeHours(practiceOfficeId: number): Promise<PracticeOfficeHour[]> {
    const { data } = await httpAuth().get(
      `office-profile/practice-office/${practiceOfficeId}/hour/all`
    );
    
    return data;
  }

  async getPracticeOfficeDefaultRates(practiceOfficeId: number): Promise<PracticeOfficeRate[]> {
    const { data } = await httpAuth().get(
      `office-profile/practice-office/${practiceOfficeId}/rate/all`
    );

    return data;
  }

  async addToFavorites(practiceOfficeId: number, userId: number): Promise<void> {
    const { data } = await httpAuth().put(
      `office-profile/practice-office/${practiceOfficeId}/user/${userId}/favorite`,
    );

    return data;
  }

  async removeFromFavorites(practiceOfficeId: number, userId: number): Promise<void> {
    const { data } = await httpAuth().delete(
      `office-profile/practice-office/${practiceOfficeId}/user/${userId}/favorite`,
    );
    
    return data;
  }

  async getPositions(): Promise<Position[]> {
    const { data } = await httpAuth().get(
      `Postings/GetPositions`
    );
    return data;
  }

  async getPostShiftDetails(practiceOfficeId: number): Promise<PostShiftDetails> {
    const { data } = await httpAuth().get(
      `Postings/GetPostShiftDetails?practiceOfficeId=${practiceOfficeId}`
    );
    return data;
  }
}

export default new PostingsService();