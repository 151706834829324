import moment from "moment";
import { OfficePayroll } from "../office-profiles/office-profiles.types";

export class JobPost {
  constructor(jobPost: IPracticeOfficeJobPost, shiftStatus: ShiftStatus) {
    const start = moment(jobPost.startDateTime).format("h:mm A");
    const end = moment(jobPost.endDateTime).format("h:mm A");
    const date = moment(jobPost.startDateTime).format("dddd, MM/DD/YYYY");

    this.id = jobPost.jobPostGroupId;
    this.postedByUserId = jobPost.postedByUserId;
    this.location = jobPost.name;
    this.practiceOfficeId = jobPost.practiceOfficeId;
    this.date = new Date(date);
    this.time = `${start} - ${end}`;
    this.position = jobPost.positionName;
    this.rate = `$${jobPost.payRate.toFixed(2)} / hr`;
    this.speakSpanish = jobPost.speakSpanish;
    this.doubleChannel = jobPost.doubleChannel;
    this.positionAbbreviation = jobPost.positionAbbreviation;
    this.posterFirstName = jobPost.posterFirstName;
    this.posterLastName = jobPost.posterLastName;
    this.postDate = new Date(jobPost.postDate);
    this.shiftStatus = shiftStatus;
  }

  id: number;
  postedByUserId: number;
  posterFirstName: string;
  posterLastName: string;
  postDate: Date;
  location: string;
  practiceOfficeId: number;
  date: Date;
  time: string;
  position: string;
  positionAbbreviation?: string;
  rate: string;
  speakSpanish: boolean;
  doubleChannel: boolean;
  shiftStatus: ShiftStatus;
}

export class AcceptedJobPost extends JobPost {
  constructor(jobPost: IPracticeOfficeJobPost, status: JobPostGroupResponseStatus, shiftStatus: ShiftStatus) {
    super(jobPost, shiftStatus);

    const response = jobPost.jobGroupPostResponses
    .find((jobPostGroupResponse: IJobGroupPostResponse) =>
      jobPostGroupResponse.jobPostGroupResponseStatus === status
    ) as IJobGroupPostResponse;
    const name = `${response.firstName} ${response.lastName}`;


    this.userId = response.userId;
    this.name = name;
    this.rating = response.avgPracticeOfficeRatingOfDA ?? 0;
    this.email = response.email;
    this.phone = response.phoneNumber;
    this.isFavorite = response.isFavorite;
  }

  userId: number;
  name: string;
  rating: number;
  email: string | null;
  phone: string | null;
  isFavorite: boolean;
}

export interface IOpenJobPost extends JobPost {
  favoritesOnly: boolean;
}

export interface IRequestedJobPost extends JobPost {
  requestedWorkers: RequestedWorker[];
}

export class RequestedWorker {
  constructor(jobGroupPostResponse: IJobGroupPostResponse) {
    const name = `${jobGroupPostResponse.firstName} ${jobGroupPostResponse.lastName}`;

    this.name = name,
    this.email = jobGroupPostResponse.email,
    this.phone = jobGroupPostResponse.phoneNumber,
    this.rating = jobGroupPostResponse.avgPracticeOfficeRatingOfDA ?? 0,
    this.userId = jobGroupPostResponse.userId,
    this.jobPostGroupResponseId = jobGroupPostResponse.jobPostGroupResponseId,
    this.isFavorite = jobGroupPostResponse.isFavorite
  }

  name: string;
  rating: number;
  email: string | null;
  phone: string | null;
  userId: number;
  jobPostGroupResponseId: number;
  isFavorite: boolean;
}

export class EmployeeDetailsModel {
  name = "";
  date = new Date();
  timeIn = "";
  timeOut = "";
  position = "";
  rating = 0;
  email: string  | null = null;
  phone : string | null = null;
  rate = "";
  initials = "";
  isFavorite = false;
  id = 0;
}

export class QuickShiftModel {
  postTo = 0;
  date = "";
  timeIn = "";
  timeOut = "";
  breakTime: number | null = null;
  positionId: number | null = null;
  payRate: number  | null = null;
  speakSpanish = false;
  channel: number | null = null;
}

export class JobPostModel {
  practiceOfficeId = 0;
  termsLookUpId = 0;
  groupName = "";
  payRate = 0.0;
  postDate = "";
  positionId = 0;
  favoritesOnly = false;
  breakTime = 0;
  removeByDate: string  | null = null;
  startDate = "";
  endDate = "";
  speakSpanish = false;
  doubleChannel = false;
  postedByUserId = 0;
}

export class PracticeOfficeModel {
  practiceOfficeId = 0;
  officeName = "";
  address1 = "";
  address2 = "";
  city = "";
  state = "";
  zipCode = "";
  phone = "";
  emailAddress = "";
  website = "";
  image = "";
  practiceId = 0;
  practiceName = "";
  payroll = new OfficePayroll();
}

export interface IPracticeOfficeJobPost {
  jobPostGroupId: number;
  practiceOfficeId: number;
  jobPostId: number;
  postedByUserId: number;
  posterFirstName: string;
  posterLastName: string;
  positionRate: number;
  payRate: number;
  groupName: string;
  postDate: string;
  removeByDate: string | null;
  startDateTime: string;
  endDateTime: string;
  positionName: string;
  positionAbbreviation?: string;
  name: string;
  jobPostStatus: JobPostStatus;
  jobGroupPostResponses: IJobGroupPostResponse[];

  speakSpanish: boolean;
  doubleChannel: boolean;
  favoritesOnly: boolean;
}

export interface IJobGroupPostResponse {
  jobPostGroupResponseId: number;
  jobPostGroupId: number;
  userId: number;
  jobPostGroupResponseStatus: JobPostGroupResponseStatus;
  lastName: string
  firstName: string;
  email: string;
  phoneNumber: string;
  avgPracticeOfficeRatingOfDA: number | null;
  isFavorite: boolean;
}

export class JobPostGroupStatusDto {
  jobPostGroupId= 0;
  status = 0;
}

export class JobPostGroupSetDto {
  jobPostGroupResponseId= 0;
  userId = 0;
  status = 0;
}

export class Position {
  positionId = 0;
  name = "";
  description = "";
  sortOrder = 0;
  Status = 0;
}

export class PracticeOfficeHour {
  practiceOfficeId = 0;
  dayOfWeek = 0;
  openTime = "";
  closeTime = "";
  breakMinutes = 0;
}

export class PracticeOfficeRate {
  positionId = 0;
  name = "";
  amount = 0.00;
}

export class PracticeOfficeUserDto {
  userId = 0;
  practiceOfficeId = 0;
}

export class PostShiftDetails {
  favoritesCount = 0;
}

export enum JobPostStatus {
  Open = 72,
  Requested = 89,
  Pending = 74,
  Confirmed = 73,
  Deleted = 77
}


export enum Positions {
  DentalHygienist = 2
}

export enum JobPostGroupResponseStatus {
  Accepted = 81,
  Confirmed = 83,
  Cancelled = 87,
  Requested = 80
}

export enum ShiftStatus {
  Requested = 'Requested',
  Confirmed = 'Confirmed',
  Pending = 'Pending',
  Posted = 'Posted'
}