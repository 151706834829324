import { Component, Vue } from 'vue-property-decorator';

@Component
export default class PostingsMixin extends Vue {
  timerRef = 0;

  getInitials (name: string): string {
    const names = name.split(' ')
    return `${names[0].charAt(0)}${names[1] ? names[1].charAt(0) : ''}`
  }

  getTimes(time: string, field: number, char: string): string {
    const times = time.split(char);
    if (times.length < field) {
      return '';
    }
    return times[field];
  }

  async fetchJobPostings(practiceOfficeId?: number): Promise<void> {
    this.$store.dispatch('Postings/resetJobPosts');
    await this.$store.dispatch('Postings/fetchJobPostings', practiceOfficeId);
  }

  emitLoading(value: boolean): void {
    this.$emit('loading', value);
  }
}