import { RouteConfig } from "vue-router";
import DentalTempsComponent from "@/views/dental-temps/dental-temps.vue";
import { Roles } from "@/views/authenticate/authenticate.types";

export const DENTAL_TEMPS_ROUTES: RouteConfig[] = [
  {
    path: "/dental-temps",
    name: "DentalTemps",
    component: DentalTempsComponent,
    meta: {
      roles: [Roles.PracticeUser, Roles.OfficeUser],
      nav: true,
      navTitle: "Dental Temps",
      navIcon: "fa-user-friends"
    }
  }
];