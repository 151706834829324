import { IAddress } from "@/views/office-profiles/office-profiles.types";

const getAddressString = function(address: IAddress): string {
  if (address.address2) {
    return `${address.address1} ${address.address2}, ${address.city}, ${address.state}, ${address.zipCode}`;
  } 
  else {
    return `${address.address1}, ${address.city}, ${address.state}, ${address.zipCode}`;
  }
}

export { getAddressString };