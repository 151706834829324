import httpAuth from "../common/http/http-common";
const API_URL = process.env.VUE_APP_API_URL;

class AuthService {
  requestHttp = httpAuth({ requiresAuth: false });

  async login(username: string, password: string) {
    localStorage.setItem("user", JSON.stringify({ username, password }));
    const response = await this.requestHttp
      .post(API_URL + "/auth/login", {
        username,
        password
      });
      if (response.data.token) {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem(
          "user",
          JSON.stringify({
            userId: response.data.id,
            username: response.data.username
          })
        );
      }
      return response.data;
  }

  async register(firstname: string, lastname: string, email: string, password: string, confirm: string) {
    const response = await this.requestHttp
      .post(API_URL + "/auth/register", {
        firstname,
        lastname,
        email,
        password,
        confirm
      });
      return response.data;
  }

  logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
  }
}

export default new AuthService();