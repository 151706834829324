import { RouteConfig } from "vue-router";
import WizardComponent from "@/views/wizard/wizard.vue";
import { Roles } from "@/views/authenticate/authenticate.types";

export const WIZARD_ROUTES: RouteConfig[] = [
  {
    path: "/wizard",
    name: "Wizard",
    component: WizardComponent,
    meta: {
      roles: [Roles.PracticeUser],
    }
  }
];