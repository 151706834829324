import { getField, updateField } from "vuex-map-fields";
import { VuexModule, Module, Action } from "vuex-module-decorators";
import AuthenticateService from "./authenticate.service";
import { 
  LoginDto,
  RegisterDto,
  NewPasswordDto,
  ErrorType,
 } from "./authenticate.types";
import { AxiosError } from "axios";

@Module({ namespaced: true })
class Authenticate extends VuexModule {
  loggedIn = false;
  errorMessage: string | null = "";
  errorType: ErrorType | null = null;
  confirmationSent = false;

  @Action({ rawError: true })
  async login(loginDto: LoginDto): Promise<void> {
    try {
      this.context.commit("updateField", {
        path: "confirmationSent",
        value: false
      });
      const token = await AuthenticateService.login(loginDto);
      if (token) {   
        sessionStorage.setItem("token", token);
        await AuthenticateService.storeUserInfo();
      }
    } catch (e: unknown) {
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('user-info');
      const errorMessage = (e as AxiosError).response?.data.message;
      const errorType = (e as AxiosError).response?.data.type;
      this.context.commit("updateField", {
        path: "errorMessage",
        value: errorMessage
      });
      this.context.commit("updateField", {
        path: "errorType",
        value: errorType
      });
    }
  }

  @Action({ rawError: true })
  async storeUserInfo(): Promise<void> {
    try {
      await AuthenticateService.storeUserInfo();
    } catch (e: unknown) {
      const errorMessage = (e as AxiosError).response?.data.message;
      this.context.commit("updateField", {
        path: "errorMessage",
        value: errorMessage
      });
    }
  }

  @Action({ rawError: true })
  async register(registerDto: RegisterDto) {
    try {
      await AuthenticateService.register(
        registerDto
      )
    } catch (e: any) {
      const errorMessage = e.response.data.message;
      this.context.commit("updateField", {
        path: "errorMessage",
        value: errorMessage
      });
    }
  }

  @Action({ rawError: true })
  async resetPassword(newPasswordDto: NewPasswordDto) {
    try {
      return await AuthenticateService.resetPassword(
        newPasswordDto
      )
    } catch (e: any) {
      const errorMessage = e.response.data.message;
      this.context.commit("updateField", {
        path: "errorMessage",
        value: errorMessage
      });
    }
  }

  @Action({ rawError: true })
  async requestPasswordReset(email: string) {
    try {
      return await AuthenticateService.requestPasswordReset(
        email
      )
    } catch (e: any) {
      const errorMessage = e.response.data.message;
      this.context.commit("updateField", {
        path: "errorMessage",
        value: errorMessage
      });
    }
  }

  @Action({ rawError: true })
  async showWizard(userId: number) {
    try {
      return await AuthenticateService.showWizard(userId);
    } catch (e) {
      console.error(e);
    }
  }

  @Action({ rawError: true })
  async resendConfirmation(model: LoginDto): Promise<void> {
    try {
      await AuthenticateService.resendConfirmation(model);
      this.context.commit("updateField", {
        path: "errorMessage",
        value: null
      });
      this.context.commit("updateField", {
        path: "errorType",
        value: null
      });
      this.context.commit("updateField", {
        path: "confirmationSent",
        value: true
      });
    } catch (e: unknown) {
      const errorMessage = (e as AxiosError).response?.data.message;
      const errorType = (e as AxiosError).response?.data.type;
      this.context.commit("updateField", {
        path: "errorMessage",
        value: errorMessage
      });
      this.context.commit("updateField", {
        path: "errorType",
        value: errorType
      });
    }
  }
}

Authenticate.getters = { getField };
Authenticate.mutations = { updateField };

export default Authenticate;