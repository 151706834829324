
import { Component, Vue, Prop } from "vue-property-decorator";
import { EmployeeDetailsModel, EmployeeKey } from "./component.types";
import EmployeeDetailsService from "./component.service";
import { mapState } from "vuex";
import { PracticeOfficeModel } from "@/views/postings/postings.types";
import { format as phoneFormatter } from "@/utils/phoneUtils";

@Component({
  computed: {
    ...mapState("Postings", 
    [
      "selectedOffice", 
    ]),
  }
})
export default class EmployeeDetails extends Vue {
  @Prop() employeeKey!: EmployeeKey;
  @Prop({ default: undefined }) officeId!: number | undefined;
  @Prop() isVisible!: boolean;

  selectedOffice!: PracticeOfficeModel;
  model: EmployeeDetailsModel = new EmployeeDetailsModel();
  isBlocked = false;
  loading = false;
  phoneFormatter = phoneFormatter;

  fields = [
    { key: 'entryDate', label: 'Date', sortable: false, thClass: 'font-gray', tdClass: 'font-gray' },
    { key: 'office', label: 'Office', sortable: false, thClass: 'font-gray', tdClass: 'font-gray' },
    { key: 'rating', label: 'Rating', sortable: false, thClass: 'font-gray', tdClass: 'font-gray rating-col' },
    { key: 'comments', label: 'Comment', sortable: false, thClass: 'font-gray', tdClass: 'font-gray' }
  ];

  isFavorite: boolean | undefined;

  get showFavorite(): boolean {
    return this.officeId != null;
  }

  get initials(): string {
    if (!this.model)
      return "NN";
    return `${this.model.firstName?.[0]}${this.model.lastName?.[0]}`;
  }

  get favoriteText(): string {
    return this.model.isFavorite ? "Remove from Favorites" : "Add to Favorites";
  }

  async toggleFavorite(): Promise<void> {
    var newValue = !this.model.isFavorite;
    var verb = newValue ? "addToFavorites" : "removeFromFavorites";
    await this.$store.dispatch(`Postings/${verb}`, this.employeeKey.userId);
    this.model.isFavorite = newValue;
    this.$emit("change");
  }

  async showCertificate(certificateId: number): Promise<void> {    
    const blob = await EmployeeDetailsService.getCertificateImage(certificateId);
    const blobUrl = URL.createObjectURL(blob);
    window.open(blobUrl, '_blank');
  }

  async created(): Promise<void> {
    this.loading = true;
    this.model = await EmployeeDetailsService.getPracticeRatingsOfDt(this.employeeKey.practiceId, this.employeeKey.userId, this.officeId);
    this.isBlocked = await EmployeeDetailsService.getBlockStatus(this.employeeKey.practiceId, this.employeeKey.userId);
    this.isFavorite = this.model.isFavorite;
    this.loading = false;
  }

  async onBlockStatusChange(): Promise<void> {
    await EmployeeDetailsService.toggleBlockStatus(this.employeeKey.practiceId, this.employeeKey.userId);
    await this.$store.dispatch("DentalTemps/fetchAllEmplyees");
    await this.$store.dispatch("DentalTemps/fetchPracticeOfficeEmplyees", this.selectedOffice.practiceOfficeId);

    this.$emit("change");
  }

  closeModal(): void {
    if (this.isFavorite != this.model.isFavorite)
      this.$emit('isFavoriteChanged', {isFavorite: this.model.isFavorite, rating: this.model.rating});
    this.$emit('update:isVisible', false);
  }
}
