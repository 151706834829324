import httpAuth from "@/common/http/http-common";
import { VacancyDetailsDto, IVacancyDto, VacancyFormDataDto, VacancyFormDto } from "./vacancies-types";

class VacancyService {
  async get(practiceId: number): Promise<IVacancyDto[]> {
    const { data } = await httpAuth().get(`Vacancy/Get?practiceId=${practiceId}`);
    return data;
  }

  async getForm(id: number): Promise<VacancyFormDto> {
    const { data } = await httpAuth().get(`Vacancy/GetForForm?id=${id}`)
    return data;
  }

  async getFormData(practiceId: number): Promise<VacancyFormDataDto> {
    const { data } = await httpAuth().get(`Vacancy/GetFormData?practiceId=${practiceId}`);
    return data;
  }

  async getDetails(id: number, practiceId: number): Promise<VacancyDetailsDto> {
    const { data } = await httpAuth().get(`Vacancy/GetDetails?id=${id}&practiceId=${practiceId}`);
    return data;
  }

  async delete(id: number): Promise<void> {
    await httpAuth().delete(`Vacancy/Delete?id=${id}`);
  }

  async toggleInterest(userId: number, vacancyId: number, isInterested: boolean | null): Promise<void> {
    let uri = `Vacancy/ToggleInterest?userId=${userId}&vacancyId=${vacancyId}`;

    if (isInterested !== null) {
      uri += `&isInterested=${isInterested}`;
    }

    await httpAuth().put(uri);
  }

  async toggleFill(vacancyId: number): Promise<void> {
    await httpAuth().put(`Vacancy/ToggleFill?vacancyId=${vacancyId}`);
  }

  async save(form: VacancyFormDto): Promise<void> {
    await httpAuth().put("Vacancy/Save", form);
  }
}

export default new VacancyService();