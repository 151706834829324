
import { Component, Mixins } from "vue-property-decorator";
import { InformationFormDto, WizardStep, PayrollFormDto } from "../wizard.types";
import WizardMixin from "../wizard.mixin";
import { mapState } from "vuex";
import { EmployeeType, PayrollType } from "@/views/office-profiles/office-profiles.types";
import enumUtils from '@/utils/enumUtils';

@Component({
  computed: {
    ...mapState('Wizard', {
      form: 'payrollForm',
      infoForm: 'infoForm'
    })
  }
})
export default class WizardRatesComponent extends Mixins(WizardMixin) {
  form!: PayrollFormDto;
  infoForm!: InformationFormDto;
  employeeTypeOptions = enumUtils.toOptions(
    EmployeeType,
    [
      { text: 'W2', value: EmployeeType.WTwo},
      { text: '1099', value: EmployeeType.OneZeroNineNine }
    ]
  );
  payrollTypeOptions = enumUtils.toOptions(
    PayrollType,
    [
      { text: 'End of day (EOD) - Dental Temp will be paid at the end of their shift.', value: PayrollType.EndOfDay },
      { text: 'Weekly (wkly) - Payroll is processed once a week.', value: PayrollType.Weekly },
      { text: 'Biweekly (biwk) - Payroll is processed every other week.', value: PayrollType.Biweekly },
      { text: `Direct Deposit (DD) - Payroll is processed using direct deposit 2-7 days after shift. 
      Deposit times vary depending on the EFT transfer process.`, value: PayrollType.DirectDeposit }
    ]
  );
  maxDescLength = 200;

  PayrollType = PayrollType;
  WizardStep = WizardStep;

  finishForm(): void {
    this.$validator.validateAll().then((isValid: boolean) => {
      if (isValid) {
        this.$validator.reset();
        
        this.next(WizardStep.Complete);
      }
    });
  }
}
