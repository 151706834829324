import httpAuth from "@/common/http/http-common";
import { UpsertSchedulerDto } from "./schedulers.types";

class SchedulersService {
  async getPracticeSchedulers(practiceId: number) {
    const { data } = await httpAuth().get(
      `Schedulers/GetPracticeSchedulers?practiceId=${practiceId}`
    );
    return data;
  }

  async getPracticeOffices(practiceId: number) {
    const { data } = await httpAuth().get(
      `Schedulers/GetPracticeOffices?practiceId=${practiceId}`
    );
    return data;
  }

  async upsertScheduler(upsertSchedulerDto: UpsertSchedulerDto) {
    const { data } = await httpAuth().post(
      `Schedulers/UpsertScheduler`,
      upsertSchedulerDto
    );
    return data;
  }
}

export default new SchedulersService();