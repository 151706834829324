
import { Component, Mixins } from 'vue-property-decorator';
import { mapState } from 'vuex';
import WizardMixin from './wizard.mixin';
import { WizardStep } from './wizard.types';
import Header from './header.vue';
import Welcome from './steps/wizard-welcome.vue';
import Information from './steps/wizard-information.vue';
import Schedulers from './steps/wizard-scheduler.vue';
import Hours from './steps/wizard-hours.vue';
import Rates from './steps/wizard-rates.vue';
import Payrolls from './steps/wizard-payrolls.vue';
import Complete from './steps/wizard-complete.vue';

@Component({
  components: {
    Header,
    Welcome,
    Information,
    Schedulers,
    Hours,
    Rates,
    Payrolls,
    Complete
  },
  computed: {
    ...mapState('Wizard', ['currentStep',])
  }
})
export default class WizardComponent extends Mixins(WizardMixin) {
  currentStep!: WizardStep;
  WizardStep = WizardStep;

  async finish(): Promise<void> {
    this.toggleLoading();

    const result = await this.$store.dispatch("Wizard/finish");

    if (!result) {
      this.toggleLoading();

      return;
    }

    await this.$store.dispatch("Authenticate/storeUserInfo");

    this.$router.push("/postings");

    this.toggleLoading();
  }
}
