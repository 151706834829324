import { Component, Vue } from "vue-property-decorator";
import { IUserInfo } from "./authenticate.types";

@Component
export default class AuthenticateMixin extends Vue {
  userInfo(): IUserInfo | undefined {
    const userInfo = sessionStorage.getItem("user-info");
    
    if (userInfo){
      return JSON.parse(userInfo) as IUserInfo;
    }
  }

  get token(): string | undefined {
    const token = sessionStorage.getItem("token");

    if (token) {
      return token;
    }
  }
}