import { getField, updateField } from "vuex-map-fields";
import { VuexModule, Module, Action } from "vuex-module-decorators";
import SchedulersService from "./schedulers.service";
import { 
  PracticeUser, 
  PracticeOfficeDropdown,
  UpsertSchedulerDto 
} from "./schedulers.types";

@Module({ namespaced: true })
class Schedulers extends VuexModule {
  selectedUser: PracticeUser = new PracticeUser();
  practiceUsers: PracticeUser[] = [];
  options: PracticeOfficeDropdown[] = [];
  errorMessage: string | null = "";

  @Action({ rawError: true })
  async fetchPracticeUsers(practiceId: number) {
    try {
      const practiceUsers = await SchedulersService.getPracticeSchedulers(
        practiceId
      );
      this.context.commit("updateField", {
        path: "practiceUsers",
        value: practiceUsers
      });
    } catch (e) {
      console.error(e);
    }
  }

  @Action({ rawError: true })
  async fetchPracticeOffices(practiceId: number) {
    try {
      const practiceOffices = await SchedulersService.getPracticeOffices(practiceId);
      this.context.commit("updateField", {
        path: "options",
        value: practiceOffices.map((office: any) => {
          return {
            text: office.officeName,
            value: office.practiceOfficeId
          }
        })
      });
    } catch (e) {
      console.error(e);
    }
  }

  @Action({ rawError: true })
  async upsertScheduler(upsertSchedulerDto: UpsertSchedulerDto) {
    try {
      this.context.commit("updateField", {
        path: "errorMessage",
        value: null
      });
      await SchedulersService.upsertScheduler(upsertSchedulerDto);
    } catch (e: any) {
      const errorMessage = e.response.data.message;
      this.context.commit("updateField", {
        path: "errorMessage",
        value: errorMessage
      });
    }
  }
}

Schedulers.getters = { getField };
Schedulers.mutations = { updateField };

export default Schedulers;