
import { Component, Prop, Mixins } from "vue-property-decorator";
import { 
  AcceptedJobPost,
  EmployeeDetailsModel ,
  JobPostStatus,
  PracticeOfficeModel
} from "../postings.types";
import HorizontalTrackingComponent from "./components/horizontal-tracking.vue";
import VerticalTrackingComponent from "./components/vertical-tracking.vue";
import EmployeeDetails from "../modals/employee-details.vue";
import PostingsMixin from "../postings.mixin";
import { mapState } from "vuex";
import { PayrollType } from "@/views/office-profiles/office-profiles.types";

@Component({
  components: {
    HorizontalTrackingComponent,
    VerticalTrackingComponent,
    EmployeeDetails
  },
  computed: {
    ...mapState("Postings", ["selectedOffice"])
  }
})
export default class PendingCard extends Mixins(PostingsMixin) {
  @Prop() pendingJob!: AcceptedJobPost;

  employeeDetails: EmployeeDetailsModel = new EmployeeDetailsModel;
  JobPostStatus = JobPostStatus;
  showHistory = false;
  modalShow = false;
  selectedOffice!: PracticeOfficeModel;

  getClasses(val: boolean): string[] {
    return val ? ['fas', 'fa-check-circle'] : ['far', 'fa-circle'];
  }

  get eodValue(): boolean {
    return this.selectedOffice.payroll?.type === PayrollType.EndOfDay;
  }

  get details(): string {
    return [
      this.pendingJob.date.toLocaleDateString("en-US", {
        weekday: 'long',
        month: 'numeric',
        year: 'numeric',
        day: 'numeric'
      }),
      this.pendingJob.position,
      this.pendingJob.time,
      this.pendingJob.rate
    ].join(' · ')
  }

  getEmployeeDetails(): void {
    this.employeeDetails = {
      name: this.pendingJob.name,
      rating: this.pendingJob.rating,
      phone: this.pendingJob.phone,
      email: this.pendingJob.email,
      date: this.pendingJob.date,
      position: this.pendingJob.position,
      rate: this.pendingJob.rate,
      timeIn: this.getTimes(this.pendingJob.time, 0, " - "),
      timeOut: this.getTimes(this.pendingJob.time, 1, " - "),
      initials: this.getInitials(this.pendingJob.name),
      isFavorite: this.pendingJob.isFavorite,
      id: this.pendingJob.userId
    };
    this.modalShow = true;
  }
}
