import { Component, Vue } from 'vue-property-decorator';
import { ErrorBag, Field } from 'vee-validate';
import { mapState } from 'vuex';
import { WizardStep } from './wizard.types';

@Component({
  computed: {
    ...mapState('Wizard', ['isLoading'])
  }
})
export default class WizardMixin extends Vue {
  veeFields!: Field;
  veeErrors!: ErrorBag;
  isLoading!: boolean;

  validateState(ref: keyof Field): boolean | null {
    if (
      this.veeFields[ref] &&
      (this.veeFields[ref].dirty || 
      this.veeFields[ref].validated)
    ) {
      return !this.veeErrors.has(ref);
    }
    return null;
  }

  toggleLoading(): void {
    this.$store.commit('Wizard/updateField', {
      path: "isLoading",
      value: !this.isLoading
    });
  }

  next(step: WizardStep): void {
    this.$store.commit('Wizard/updateField', {
      path: 'currentStep',
      value: step
    });
  }
}