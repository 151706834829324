
import { Component, Vue, Prop } from "vue-property-decorator";
import { JobPostStatus } from "../../postings.types";

@Component
export default class VerticalTrackingComponent extends Vue {
  @Prop() status!: JobPostStatus;

  JobPostStatus = JobPostStatus;

  getCircleStyle(status: JobPostStatus, color: string) {
    const object = Object.values(JobPostStatus);
    return object.indexOf(status) <= object.indexOf(this.status) ?
      `border: 1px solid ${color}; background: ${color};` :
      `border: 1px dashed #C0BEBE; background: white;`;
  }

  getLineStyle(status: JobPostStatus) {
    const object = Object.values(JobPostStatus);
    return object.indexOf(status) <= object.indexOf(this.status) ?
      `border-left: 1px solid #C0BEBE;` :
      `border-left: 1px dashed #C0BEBE;`;
  }

  showLog(status: JobPostStatus) {
    const object = Object.values(JobPostStatus);
    return object.indexOf(status) <= object.indexOf(this.status);
  }
}
