
  import { Component, Vue, Prop } from "vue-property-decorator";

  @Component
  export default class ConfirmDialog extends Vue {
    @Prop() confirmShow!: boolean;
    @Prop() title!: boolean;
    @Prop() message!: boolean;

    closeModal() {
      this.$emit('update:confirmShow', false);
    }

    confirmed() {
      this.$emit('confirmed');
      this.$emit('update:confirmShow', false);
    }

    cancel() {
      this.$emit('rejected');
      this.$emit('update:confirmShow', false);
    }
  }
