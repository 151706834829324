
import { Vue, Component, Prop } from 'vue-property-decorator';
import FilterPopover from './filter-popover.vue';

@Component({
  components: {
    FilterPopover
  }
})
export default class ColumnHeader extends Vue {
  @Prop() id!: string;
  @Prop() label!: string;
  @Prop() activeFilter!: boolean;
  @Prop() data!: { label: string, field: { key: string, filterId: string, sortable: boolean } };
  @Prop({ default: null }) sortBy!: string | null;
  @Prop({ default: false }) sortDesc!: boolean;
  @Prop() tableData!: Record<string, unknown>;
  @Prop() filters: unknown;
}
