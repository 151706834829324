import { getField, updateField } from "vuex-map-fields";
import { VuexModule, Module, Action } from "vuex-module-decorators";
import PostingsService from "./postings.service";
import { 
  IOpenJobPost,
  IRequestedJobPost,
  RequestedWorker,
  PracticeOfficeModel,
  JobPostModel,
  JobPostStatus,
  JobPostGroupStatusDto,
  JobPostGroupSetDto,
  PracticeOfficeHour,
  PracticeOfficeRate,
  Position,
  JobPostGroupResponseStatus,
  IJobGroupPostResponse,
  JobPost,
  AcceptedJobPost,
  ShiftStatus
} from "./postings.types";

@Module({ namespaced: true })
class Postings extends VuexModule {
  openJobs: IOpenJobPost[] = [];
  requestedJobs: IRequestedJobPost[] = [];
  pendingJobs: AcceptedJobPost[] = [];
  confirmedJobs: AcceptedJobPost[] = [];
  practiceOffices: PracticeOfficeModel[] = [];
  selectedOffice: PracticeOfficeModel = new PracticeOfficeModel();
  positions: Position[] = [];

  @Action({ rawError: true })
  resetJobPosts(): void {
    this.context.commit("updateField", {
      path: "openJobs",
      value: []
    });
    this.context.commit("updateField", {
      path: "requestedJobs",
      value: []
    });
    this.context.commit("updateField", {
      path: "pendingJobs",
      value: []
    });
    this.context.commit("updateField", {
      path: "confirmedJobs",
      value: []
    });
  }

  @Action({ rawError: true })
  async fetchPracticeOffices(userId: number): Promise<void> {
    try {
      const practiceOffices = await PostingsService.getPracticeOffices(userId);
      this.context.commit("updateField", {
        path: "practiceOffices",
        value: practiceOffices
      });
      if (!this.selectedOffice.practiceOfficeId){
        this.context.commit("updateField", {
          path: "selectedOffice",
          value: practiceOffices[0] ?? new PracticeOfficeModel()
        });
      }
    } catch (e) {
      console.error(e);
    }
  }

  @Action({ rawError: true })
  async fetchPositions(): Promise<void> {
    try {
      const positions = await PostingsService.getPositions();
      this.context.commit("updateField", {
        path: "positions",
        value: positions
      });
    } catch (e) {
      console.error(e);
    }
  }

  @Action({ rawError: true })
  async fetchPostShiftDetails(practiceOfficeId: number | null): Promise<void> {
    try {
      if (!practiceOfficeId)
        practiceOfficeId = this.selectedOffice.practiceOfficeId;

      const details = await PostingsService.getPostShiftDetails(practiceOfficeId);
      this.context.commit("updateField", {
        path: "favoritesCount",
        value: details.favoritesCount
      });
    } catch (e) {
      console.error(e);
    }
  }

  @Action({ rawError: true })
  async fetchPracticeOfficeHours(practiceOfficeId: number): Promise<PracticeOfficeHour[]> {
    try {
      return await PostingsService.getPracticeOfficeHours(practiceOfficeId);
    } catch (e) {
      console.error(e);
    }
    
    return [];
  }

  @Action({ rawError: true })
  async fetchPracticeOfficeDefaultRates(practiceOfficeId: number): Promise<PracticeOfficeRate[]> {
    try {
      return await PostingsService.getPracticeOfficeDefaultRates(practiceOfficeId);
    } catch (e) {
      console.error(e);
    }

    return [];
  }

  @Action({ rawError: true })
  async postShift(jobPost: JobPostModel): Promise<void> {
    try {
      await PostingsService.postShift(jobPost);
    } catch (e) {
      console.error(e);
    }
  }

  @Action({ rawError: true })
  async setJobPostGroupStatus(jobPostGroupStatusDto: JobPostGroupStatusDto): Promise<void> {
    try {
      await PostingsService.setJobPostGroupStatus(jobPostGroupStatusDto);
    } catch (e) {
      console.error(e);
    }
  }

  @Action({ rawError: true })
  async setJobPostGroupResponseStatus(jobPostGroupSetDto: JobPostGroupSetDto): Promise<void> {
    try {
      await PostingsService.setJobPostGroupResponseStatus(jobPostGroupSetDto);
    } catch (e) {
      console.error(e);
    }
  }

  @Action({ rawError: true })
  async refreshJobPostings(): Promise<void> {
    if (this.selectedOffice.practiceOfficeId)
      this.context.dispatch('fetchJobPostings', this.selectedOffice.practiceOfficeId);
  }

  @Action({ rawError: true })
  async fetchJobPostings(practiceOfficeId: number): Promise<void> {
    try {
      const jobPostings = await PostingsService.getPracticeOfficeJobPostings(practiceOfficeId);

      const openJobs: IOpenJobPost[] = jobPostings
        .filter(openJob => openJob.jobPostStatus == JobPostStatus.Open)
        .map(openJob => {
          return {
            ...new JobPost(openJob, ShiftStatus.Posted),
            favoritesOnly: openJob.favoritesOnly
          }
        });
      const requestedJobs: IRequestedJobPost[] = jobPostings
        .filter(requestedJob =>
          requestedJob.jobPostStatus == JobPostStatus.Requested &&
          requestedJob.jobGroupPostResponses.some(r => r.jobPostGroupResponseStatus !== JobPostGroupResponseStatus.Cancelled)
        )
        .map(requestedJob => {
          const requestedWorkers: RequestedWorker[] = requestedJob.jobGroupPostResponses
            .map((jobGroupPostResponse: IJobGroupPostResponse) => new RequestedWorker(jobGroupPostResponse));
        
          return {
            ...new JobPost(requestedJob, ShiftStatus.Requested),
            requestedWorkers
          };
        });
      const pendingJobs: AcceptedJobPost[] = jobPostings
        .filter(pendingJob => 
          pendingJob.jobPostStatus == JobPostStatus.Pending &&
          pendingJob.jobGroupPostResponses.some(r => r.jobPostGroupResponseStatus !== JobPostGroupResponseStatus.Cancelled)
        )
        .map(pendingJob => new AcceptedJobPost(pendingJob, JobPostGroupResponseStatus.Accepted, ShiftStatus.Pending));
      const confirmedJobs: AcceptedJobPost[] = jobPostings
        .filter(confirmedJob => confirmedJob.jobPostStatus == JobPostStatus.Confirmed)
        .map(confirmedJob => new AcceptedJobPost(confirmedJob, JobPostGroupResponseStatus.Confirmed, ShiftStatus.Confirmed));

      this.context.commit("updateField", {
        path: "openJobs",
        value: openJobs
      });
      this.context.commit("updateField", {
        path: "requestedJobs",
        value: requestedJobs
      });
      this.context.commit("updateField", {
        path: "pendingJobs",
        value: pendingJobs
      });
      this.context.commit("updateField", {
        path: "confirmedJobs",
        value: confirmedJobs
      });
    } catch (e) {
      console.error(e);
    }
  }

  @Action({ rawError: true })
  async addToFavorites(userId: number): Promise<void> {
    try {
      await PostingsService.addToFavorites(this.selectedOffice.practiceOfficeId, userId);
    } catch (e) {
      console.error(e);
    }
  }

  @Action({ rawError: true })
  async removeFromFavorites(userId: number): Promise<void> {
    try {
      await PostingsService.removeFromFavorites(this.selectedOffice.practiceOfficeId, userId);
    } catch (e) {
      console.error(e);
    }
  }
}

Postings.getters = { getField };
Postings.mutations = { updateField };

export default Postings;