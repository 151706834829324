
import moment from "moment";
import { Component, Prop, Mixins } from "vue-property-decorator";
import OfficeProfilesMixin from "./office-profiles.mixin";
import { 
  OfficeProfile
} from "./office-profiles.types";

@Component
export default class OfficeProfileCardComponent extends Mixins(OfficeProfilesMixin) {
  @Prop() office!: OfficeProfile;
  @Prop() index!: number;

  getBackgroundColor() {
    const color = this.office.color;

    return `background: ${color}; border: 1px solid ${color};`;
  }

  editOfficeProfile() {
    this.$store.commit("OfficeProfiles/updateField", {
      path: "selectedOfficeProfile",
      value: this.office
    });
    this.$store.commit("OfficeProfiles/updateField", {
      path: "isEdit",
      value: true
    });
    this.$router.push("/office-profiles-edit");
  }

  getTime(time: string) {
    return moment(time, 'HH:mm').format("hh:mm A")
  }
}
