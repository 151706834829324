
import { Component, Vue } from "vue-property-decorator";
import { ErrorBag, Field } from "vee-validate";
import { MarketingModel } from "./marketing.types";

@Component
export default class Register extends Vue {
  marketingModel: MarketingModel = {
    firstName: "",
    lastName: "",
    confirm: "",
    email: "",
    password: ""
  }

  veeFields!: Field;
  veeErrors!: ErrorBag;

  errorMessage="";

  passwordShow = false;
  confirmShow = false;

  viewPassword() {
    this.passwordShow = !this.passwordShow;
  }

  viewConfirm() {
    this.confirmShow = !this.confirmShow;
  }

  validateState(ref: keyof Field) {
    if (
      this.veeFields[ref] &&
      (this.veeFields[ref].dirty || 
      this.veeFields[ref].validated)
    ) {
      return !this.veeErrors.has(ref);
    }
    return null;
  }

  authenticate() {
    this.$validator.validateAll().then((isValid: boolean) => {
      if (isValid) {
        this.$router.push("/login");
      }
    });
  }
}
