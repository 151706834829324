
import { Component, Mixins, Prop } from "vue-property-decorator";
import OfficeProfilesMixin from "./office-profiles.mixin";
import { ErrorBag, Field } from "vee-validate";
import { AddNewUserDto } from "./office-profiles.types";

@Component
export default class OfficeNewUserComponent extends Mixins(OfficeProfilesMixin) {
  @Prop() modalShow!: boolean;

  addNewUserDto: AddNewUserDto = new AddNewUserDto();
  loading = false;
  veeFields!: Field;
  veeErrors!: ErrorBag;

  validateState(ref: string) {
    if (
      this.veeFields[ref as keyof Field] &&
      (this.veeFields[ref as keyof Field].dirty || 
      this.veeFields[ref as keyof Field].validated)
    ) {
      return !this.veeErrors.has(ref);
    }
    return null;
  }

  async addUser() {
    this.$validator.validateAll().then(async (isValid: boolean) => {
      if (isValid) {
        this.loading = true;
        const userId = await this.$store.dispatch("OfficeProfiles/addNewUser", {
          firstName: this.addNewUserDto.firstName,
          lastName: this.addNewUserDto.lastName,
          email: this.addNewUserDto.email,
          phoneNumber: this.addNewUserDto.phoneNumber,
          password: "Password!1", // fix
          practiceOfficeId: this.isEdit ? this.selectedOfficeProfile.practiceOfficeId : null,
        });
        this.loading = false;
        if (!this.errorMessage) {
          const name = `${this.addNewUserDto.firstName} ${this.addNewUserDto.lastName}`;
          const initials = `${this.addNewUserDto.firstName.charAt(0)}${this.addNewUserDto.lastName.charAt(0)}`;
          const staff = {
            practiceOfficeId: this.isEdit ? this.selectedOfficeProfile.practiceOfficeId : null,
            firstName: this.addNewUserDto.firstName,
            lastName: this.addNewUserDto.lastName,
            initials: initials,
            name: name,
            phoneNumber: this.addNewUserDto.phoneNumber,
            email: this.addNewUserDto.email,
            inOffice: true,
            userId: userId
          }; 
          this.$emit('reload', staff);
          this.$emit('update:modalShow', false);
        }
      }
    });
  }

  closeModal() {
    this.$emit('update:modalShow', false);
  }
}
