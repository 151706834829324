
import { Component, Mixins, Prop } from "vue-property-decorator";
import { EmployeeType, OfficePayrollForm, OfficeProfileUsers, PayrollType, PracticeOfficeUserStatus } from "./office-profiles.types";
import OfficeProfilesMixin from "./office-profiles.mixin";
import ValidatedFormMixin from '@/common/form/validated-form.mixin';
import { generateHexColor } from "@/utils/colorUtils";
import AuthenticateMixin from "../authenticate/authenticate.mixin";
import enumUtils from '@/utils/enumUtils';

@Component
export default class OfficePayrollEditComponent extends Mixins(OfficeProfilesMixin, ValidatedFormMixin, AuthenticateMixin) {
  @Prop() activeTab!: number;
  
  form: OfficePayrollForm = new OfficePayrollForm();
  loading = false;

  maxDescLength = 200;

  employeeTypeOptions = enumUtils.toOptions(
    EmployeeType,
    [
      { text: 'W2', value: EmployeeType.WTwo},
      { text: '1099', value: EmployeeType.OneZeroNineNine }
    ]
  );
  payrollTypeOptions = enumUtils.toOptions(
    PayrollType,
    [
      { text: 'End of day (EOD) - Dental Temp will be paid at the end of their shift.', value: PayrollType.EndOfDay },
      { text: 'Weekly (wkly) - Payroll is processed once a week.', value: PayrollType.Weekly },
      { text: 'Biweekly (biwk) - Payroll is processed every other week.', value: PayrollType.Biweekly },
      { text: `Direct Deposit (DD) - Payroll is processed using direct deposit 2-7 days after shift. 
      Deposit times vary depending on the EFT transfer process.`, value: PayrollType.DirectDeposit }
    ]
  );

  PayrollType = PayrollType;

  mounted(): void {
    this.form = {
      id: this.selectedOfficeProfile.payroll?.id,
      employeeType: this.selectedOfficeProfile.payroll?.employeeType,
      type: this.selectedOfficeProfile.payroll?.type,
      description: this.selectedOfficeProfile.payroll?.description,
      practiceOfficeId: this.selectedOfficeProfile.practiceOfficeId
    };
    
    this.$validator.reset();
  }

  async update(): Promise<void> {
    this.$validator.validateAll().then(async (isValid: boolean) => {
      if (isValid) {
        this.loading = true;
        if (this.form.type !== PayrollType.Other) {
          this.form.description = undefined;
        }
        await this.$store.dispatch("OfficeProfiles/savePayroll", this.form);
        this.loading = false;
      }
    });
  }

  async back(): Promise<void> {
    this.$emit('update:activeTab', 4);
  }

  async finish(): Promise<void> {
    this.$validator.validateAll().then(async (isValid: boolean) => {
      if (isValid) {
        this.loading = true;

        const practiceOfficeId = await this.$store.dispatch("OfficeProfiles/upsertPracticeOffice", {
          practiceId: this.userInfo()?.practiceId,
          officeName: this.selectedOfficeProfile.name,
          address1: this.selectedOfficeProfile.address1,
          address2: this.selectedOfficeProfile.address2,
          city: this.selectedOfficeProfile.city,
          state: this.selectedOfficeProfile.state,
          zipCode: this.selectedOfficeProfile.zipCode,
          image: null,
          phone: this.selectedOfficeProfile.phone,
          emailAddress: this.selectedOfficeProfile.email,
          website: this.selectedOfficeProfile.url,
          color: generateHexColor()
        });

        await this.$store.dispatch("OfficeProfiles/upsertUserToPracticeOffice", {
          userId: this.userInfo()?.sub,
          practiceOfficeId: practiceOfficeId,
          status: PracticeOfficeUserStatus.Active
        });
        
        for (var user of this.selectedOfficeProfile.staff) {
          if (user.inOffice) {
            await this.$store.dispatch("OfficeProfiles/upsertUserToPracticeOffice", {
              userId: user.userId,
              practiceOfficeId: practiceOfficeId,
              status: PracticeOfficeUserStatus.Active
            });
          }
        }
        await this.$store.dispatch("OfficeProfiles/upsertPracticeOfficeHours", {
          practiceOfficeId: practiceOfficeId,
          sundayOpenTime: this.getOpenTime("Sunday", this.selectedOfficeProfile.hours),
          sundayCloseTime: this.getCloseTime("Sunday", this.selectedOfficeProfile.hours),
          mondayOpenTime: this.getOpenTime("Monday", this.selectedOfficeProfile.hours),
          mondayCloseTime: this.getCloseTime("Monday", this.selectedOfficeProfile.hours),
          tuesdayOpenTime: this.getOpenTime("Tuesday", this.selectedOfficeProfile.hours),
          tuesdayCloseTime: this.getCloseTime("Tuesday", this.selectedOfficeProfile.hours),
          wednesdayOpenTime: this.getOpenTime("Wednesday", this.selectedOfficeProfile.hours),
          wednesdayCloseTime: this.getCloseTime("Wednesday", this.selectedOfficeProfile.hours),
          thursdayOpenTime: this.getOpenTime("Thursday", this.selectedOfficeProfile.hours),
          thursdayCloseTime: this.getCloseTime("Thursday", this.selectedOfficeProfile.hours),
          fridayOpenTime: this.getOpenTime("Friday", this.selectedOfficeProfile.hours),
          fridayCloseTime: this.getCloseTime("Friday", this.selectedOfficeProfile.hours),
          saturdayOpenTime: this.getOpenTime("Saturday", this.selectedOfficeProfile.hours),
          saturdayCloseTime: this.getCloseTime("Saturday", this.selectedOfficeProfile.hours),
          sundayBreakMinutes: this.getBreakMinutes("Sunday", this.selectedOfficeProfile.hours),
          mondayBreakMinutes: this.getBreakMinutes("Monday", this.selectedOfficeProfile.hours),
          tuesdayBreakMinutes: this.getBreakMinutes("Tuesday", this.selectedOfficeProfile.hours),
          wednesdayBreakMinutes: this.getBreakMinutes("Wednesday", this.selectedOfficeProfile.hours),
          thursdayBreakMinutes: this.getBreakMinutes("Thursday", this.selectedOfficeProfile.hours),
          fridayBreakMinutes: this.getBreakMinutes("Friday", this.selectedOfficeProfile.hours),
          saturdayBreakMinutes: this.getBreakMinutes("Saturday", this.selectedOfficeProfile.hours)
        });
        await this.$store.dispatch("OfficeProfiles/upsertPracticeOfficeRates", {
          rates: this.selectedOfficeProfile.payrates.map(officeRate => ({
            positionId: officeRate.positionId,
            practiceOfficeId: practiceOfficeId,
            amount: officeRate.rate
          })),
          practiceOfficeId: practiceOfficeId
        });
        this.form.practiceOfficeId = practiceOfficeId;
        if (this.form.type !== PayrollType.Other) {
          this.form.description = undefined;
        }
        await this.$store.dispatch("OfficeProfiles/savePayroll", this.form);

        this.loading = false;
        this.$router.push("/office-profiles");
      }
    });
  }
}
