import { RouteConfig } from "vue-router";
import { Roles } from "@/views/authenticate/authenticate.types";
import VacancyComponent from "./vacancies.vue";

export const VACANCY_ROUTES: RouteConfig[] = [
  {
    path: "/vacancies",
    name: "VacancyComponent",
    component: VacancyComponent,
    meta: {
      roles: [Roles.PracticeUser, Roles.OfficeUser],
      nav: true,
      navTitle: "For Hire",
      navIcon: "icon-h",
      customNavIcon: true
    }
  }
];