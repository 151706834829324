
import { Component, Mixins } from "vue-property-decorator";
import AuthenticateMixin from "../authenticate/authenticate.mixin";

import {  
  PracticeOfficeModel
} from "@/views/postings/postings.types";

import {  
  PracticeEmployee
} from "./dental-temps.types";

import { EmployeeDetails, EmployeeKey } from "@/components/employee-details";
import EmployeeCard from "./employee-card.vue";
import { mapState } from "vuex";

@Component({
  components: {
    EmployeeCard,
    EmployeeDetails
  },  
  computed: {
    ...mapState("Postings", 
    [
      "selectedOffice", 
      "practiceOffices",
    ]),
    ...mapState("DentalTemps", 
    [
      "employees",
    ])
  }
})

export default class DentalTempsComponent extends Mixins(AuthenticateMixin) {
  loading = false;
  nameFilter: string = "";
  practiceOffices!: PracticeOfficeModel[];
  selectedOffice!: PracticeOfficeModel;
  employees!: PracticeEmployee[];

  positions: string[] = [];
  selectedPositions: string[] = [];

  currentEmployee : EmployeeKey | null = null;
  isEmployeeDetailsVisible = false;

  get isOfficeSelectorDisabled() {
    return this.practiceOffices.length <= 1;
  }

  get favoriteUsers() {
    return this.employees.filter(x => x.isFavorite);
  }

  get regularUsers() {
    var nameFilter = this.nameFilter && this.nameFilter.toLowerCase();
    
    return this.employees.filter(
      x => !x.isFavorite &&
           (!nameFilter || x.fullName.toLowerCase().indexOf(nameFilter) != -1) &&
           (this.selectedPositions.length == 0 || x.positions.some(p => this.selectedPositions.indexOf(p) > -1))
    );
  }

  async mounted() {
    this.loading = true;
    const positions = await this.$store.dispatch("OfficeProfiles/fetchPositions");
    for (var position of positions) {
      this.positions.push(position.name);
    }
    await this.$store.dispatch("DentalTemps/fetchAllEmplyees");
    await this.fetchData();
  }

  async onOfficeSelect(value: PracticeOfficeModel) {
    this.$store.commit("Postings/updateField", {
      path: "selectedOffice",
      value: value
    });
    await this.fetchData();
  }

  async fetchData(): Promise<void> {
    this.loading = true;
    if (this.practiceOffices.length == 0) {
      await this.$store.dispatch("Postings/fetchPracticeOffices", this.userInfo()?.sub);
    }
    await this.fetchEmployees();
    this.loading = false;
  }

  isFavoriteChanged(input: any) {
    var emp = this.employees.find(x => x.id == this.currentEmployee?.userId);
    if (emp) {
      emp.isFavorite = input.isFavorite;
      emp.rating = input.rating;
    }
  }

  async fetchEmployees() {
    await this.$store.dispatch("DentalTemps/fetchPracticeOfficeEmplyees", this.selectedOffice.practiceOfficeId);
  }

  showDetails(employeeId: number): void {
    this.currentEmployee = { userId: employeeId, practiceId: this.userInfo()?.practiceId ?? 0 };
    this.isEmployeeDetailsVisible = true;
  }
}

