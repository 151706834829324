
  import { Component, Vue, Prop } from "vue-property-decorator";

  @Component
  export default class DeleteDialog extends Vue {
    @Prop() confirmShow!: boolean;

    closeModal() {
      this.$emit('update:confirmShow', false);
    }

    remove() {
      this.$emit('delete');
      this.$emit('update:confirmShow', false);
    }

    cancel() {
      this.$emit('update:confirmShow', false);
    }
  }
