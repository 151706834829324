
import { Component, Prop, Vue } from "vue-property-decorator";
import { ErrorBag, Field } from "vee-validate";
import { UserFormDto } from "@/common/types";
import { mapState } from "vuex";

@Component({
  computed: {
    ...mapState('User', {
      form: 'userForm'
    })
  }
})
export default class UserEditModalComponent extends Vue {
  @Prop() modalShow!: boolean;
  @Prop() id!: number;

  form!: UserFormDto;

  loading = false;
  veeFields!: Field;
  veeErrors!: ErrorBag;

  async mounted(): Promise<void> {
    await this.$store.dispatch("User/getForm", this.id);
  }

  validateState(ref: string): boolean | null {
    if (
      this.veeFields[ref as keyof Field] &&
      (this.veeFields[ref as keyof Field].dirty || 
      this.veeFields[ref as keyof Field].validated)
    ) {
      return !this.veeErrors.has(ref);
    }
    return null;
  }

  async update(): Promise<void> {
    this.$validator.validateAll().then(async (isValid: boolean) => {
      if (isValid) {
        this.loading = true;
        try {
          await this.$store.dispatch("User/update", this.form);
        }
        catch {
          this.loading = false;
          return;
        }
        await this.$store.dispatch("Authenticate/storeUserInfo");
        this.$emit("success");
        this.loading = false;
        this.$emit('update:modalShow', false);
      }
    });
  }

  closeModal(): void {
    this.$emit('update:modalShow', false);
  }
}
