
import { Component, Mixins, Prop } from "vue-property-decorator";
import { ErrorBag, Field } from "vee-validate";
import { OfficeModel } from "./office-profiles.types";
import { IGoogleAddressData } from "@/common/types";
import OfficeProfilesMixin from "./office-profiles.mixin";
import VueGoogleAutocomplete from "vue-google-autocomplete";

@Component({
  components: {
    VueGoogleAutocomplete
  }
})
export default class OfficeInformationEditComponent extends Mixins(OfficeProfilesMixin) {
  @Prop() activeTab!: number;
  
  addressString = "";
  officeModel: OfficeModel = new OfficeModel();
  loading = false;
  veeFields!: Field;
  veeErrors!: ErrorBag;

  mounted() {
    this.officeModel = {
      practiceName: "",
      officeName: this.selectedOfficeProfile.name,
      address1: this.selectedOfficeProfile.address1,
      address2: this.selectedOfficeProfile.address2,
      city: this.selectedOfficeProfile.city,
      state: this.selectedOfficeProfile.state,
      zipCode: this.selectedOfficeProfile.zipCode,
      website: this.selectedOfficeProfile.url,
      phone: this.selectedOfficeProfile.phone,
      practiceId: this.selectedOfficeProfile.practiceId,
      practiceOfficeId: this.selectedOfficeProfile.practiceOfficeId,
      email: this.selectedOfficeProfile.email,
      color: this.selectedOfficeProfile.color,
      payroll: this.selectedOfficeProfile.payroll
    };
    if (this.officeModel.address1 && this.officeModel.city && this.officeModel.state){
      this.setAddressString();
    }
    this.$validator.reset();
  }

  validateState(ref: keyof Field) {
    if (
      this.veeFields[ref] &&
      (this.veeFields[ref].dirty || 
      this.veeFields[ref].validated)
    ) {
      return !this.veeErrors.has(ref);
    }
    return null;
  }

  async update() {
    this.$validator.validateAll().then(async (isValid: boolean) => {
      if (isValid) {
        this.loading = true;
        await this.$store.dispatch("OfficeProfiles/upsertPracticeOffice", {
          practiceId: this.officeModel.practiceId,
          practiceOfficeId: this.officeModel.practiceOfficeId,
          officeName: this.officeModel.officeName,
          address1: this.officeModel.address1,
          address2: this.officeModel.address2,
          city: this.officeModel.city,
          state: this.officeModel.state,
          zipCode: this.officeModel.zipCode,
          image: null,
          phone: this.officeModel.phone,
          emailAddress: this.officeModel.email,
          website: this.officeModel.website,
          color: this.officeModel.color
        });
        this.loading = false;
      }
    });
  }

  getAddressClassName(): string {
    let classname = "form-control form-control-sm";
    const addressKey: keyof Field = "input-address" as keyof Field;
    const validClass = "is-valid";
    const invalidClass= "is-invalid";

    if (this.veeFields[addressKey] && (this.veeFields[addressKey].dirty || this.veeFields[addressKey].validated)){
      if (this.veeErrors.has(addressKey)) {
        classname += " " + invalidClass;
      }

      classname += " " + validClass;
    }

    return classname;
  }

  onAddressChange(value: string): void {
    this.addressString = value;
  }

  resetAddress(): void {
    this.officeModel.address1 = "";
    this.officeModel.address2 = null;
    this.officeModel.city = "";
    this.officeModel.state = "";
    this.officeModel.zipCode = "";
  }

  setAddressString(): void {
    this.addressString = this.officeModel.address1 + " " + this.officeModel.address2 + ", " + this.officeModel.city + ", " + this.officeModel.state;
  }

  getAddressString(): string {
    const addressRef = this.$refs.address as any;
    
    if (addressRef) {
      addressRef.update(this.addressString);
    }
  
    return this.addressString;
  }

  setAddressData(data: IGoogleAddressData): void {
    if (data.street_number) {
      this.officeModel.address1 = data.street_number + " " + data.route;
    }
    else {
      this.officeModel.address1 = data.route;
    }

    this.officeModel.address2 = data.subpremise ?? "";
    this.officeModel.city = data.locality;
    this.officeModel.state = data.administrative_area_level_1;
    this.officeModel.zipCode = data.postal_code;

    this.setAddressString();
  }

  async next() {
    this.$validator.validateAll().then(async (isValid: boolean) => {
      if (isValid) {
        this.selectedOfficeProfile.name = this.officeModel.officeName;
        this.selectedOfficeProfile.address1 = this.officeModel.address1;
        this.selectedOfficeProfile.address2 = this.officeModel.address2;
        this.selectedOfficeProfile.city = this.officeModel.city;
        this.selectedOfficeProfile.state = this.officeModel.state;
        this.selectedOfficeProfile.zipCode = this.officeModel.zipCode;
        this.selectedOfficeProfile.phone = this.officeModel.phone;
        this.selectedOfficeProfile.email = this.officeModel.email;
        this.selectedOfficeProfile.url = this.officeModel.website;
        this.$emit('update:activeTab', 2);
      }
    });
  }
}
