
import { Component, Mixins } from "vue-property-decorator";
import { InformationFormDto, IRateFormDto, WizardStep } from "../wizard.types";
import WizardMixin from "../wizard.mixin";
import { mapState } from "vuex";

@Component({
  computed: {
    ...mapState('Wizard', {
      forms: 'rateForms',
      infoForm: 'infoForm'
    })
  }
})
export default class WizardRatesComponent extends Mixins(WizardMixin) {
  infoForm!: InformationFormDto;
  forms!: IRateFormDto[];
  WizardStep = WizardStep;
  
  async created(): Promise<void> {
    if (this.forms.length) {
      return;
    }

    this.toggleLoading();
    this.$store.commit('Wizard/updateField', { path: 'rateForms', value: [] });
    const positions = await this.$store.dispatch("Wizard/fetchPositions");
    for (const position of positions) {
      this.forms.push({
        position: position.name,
        rate: 0,
        positionId: position.positionId,
      });
    }
    this.toggleLoading();
  }

  finishForm(): void {
    if (this.forms.length) {
      this.next(WizardStep.Payrolls);
    }

    this.$validator.validateAll();
  }
}
