
import { Component, Mixins } from "vue-property-decorator";
import AuthenticateMixin from "../authenticate/authenticate.mixin";
import {
  RatingItemsModel,
  RatingsStatus
} from "./ratings.types";
import { mapState } from "vuex";
import { EmployeeDetails, EmployeeKey } from "@/components/employee-details";
import ConfirmDialog from "@/views/dialog/confirm-dialog.vue";

@Component({
  computed: {
    ...mapState("Ratings", 
    [
      "newRatings",
      "oldRatings"
    ])
  },
  components: {
    EmployeeDetails,
    ConfirmDialog
  },
})
export default class RatingsComponent extends Mixins(AuthenticateMixin) {
  oldRatings!: RatingItemsModel[];

  currentSortBy = "";
  currentSortDesc = false;
  pastSortBy = "";
  pastSortDesc = false;
  confirmModalShow = false;
  loading = true;
  currentRatingItem: RatingItemsModel | null = null;

  currentEmployee : EmployeeKey | null = null;

  isEmployeeDetailsVisible = false;

  filters = {
    id: '',
    date: '',
    member: '',
    office: '',
    rating: '',
    comments: '',
    disabled: '',
    log: '',
    userId:'',
    noShow: ''
  }
        
  fields = [
    { key: 'date', label: 'Date', sortable: true },
    { key: 'member', label: 'Team Member', sortable: true },
    { key: 'office', label: 'Office', sortable: true },
    { key: 'rating', label: 'Shift Rating', sortable: true },
    { key: 'noShow', label: 'No Show', sortable: false, thStyle: {verticalAlign: 'top'} },
    { key: 'comments', label: 'Comments', sortable: true }
  ];

  showDetails(employeeId: number) {
    this.currentEmployee = { userId: employeeId, practiceId: this.userInfo()?.practiceId ?? 0 };
    this.isEmployeeDetailsVisible = true;
  }

  showWarning(isChecked: boolean) {
    if (isChecked)
      this.confirmModalShow = true;
  }

  async mounted() {
    await this.fetchData();
  }

  async fetchData() {
    this.loading = true;
    await this.$store.dispatch("Ratings/fetchPracticeOfficeRatings", this.userInfo()?.practiceId);
    this.loading = false;
  }

  get filtered () {
    const filtered = this.oldRatings.filter(item => {
      return Object.keys(this.filters).every(key =>
          String(item[key as keyof RatingItemsModel]).toLowerCase().
            includes(this.filters[key as keyof RatingItemsModel].toLowerCase()))
    })
    return filtered.length > 0 ? filtered : []
  }

  async dismissNewItems(item: RatingItemsModel) {
    this.loading = true;
    await this.$store.dispatch("Ratings/updateJobPostRating", {
      jobPostRatingByPracticeOfficeId: item.id,
      rating: 0,
      comments: null,
      status: RatingsStatus.Dismissed,
      ratedByPracticeOfficeUserId: this.userInfo()?.sub
    });
    await this.$store.dispatch("Ratings/fetchPracticeOfficeRatings", this.userInfo()?.practiceId);
    this.loading = false;
  }

  async validatedAndSave(item: RatingItemsModel) {
    if (item.noShow) {
      this.confirmModalShow = true;
      this.currentRatingItem = item;
    } else {
      await this.saveItems(item);
    }
  }

  reject() {
    if (this.currentRatingItem != null)
      this.currentRatingItem.noShow = false;
    this.currentRatingItem = null;
  }

  async confirm() {
    if (this.currentRatingItem != null)
      await this.saveItems(this.currentRatingItem);
    this.currentRatingItem = null;
  }

  async saveItems(item: RatingItemsModel) {
    this.loading = true;
    item.disabled = true;
    await this.$store.dispatch("Ratings/updateJobPostRating", {
      jobPostRatingByPracticeOfficeId: item.id,
      rating: item.rating,
      comments: item.comments,
      status: RatingsStatus.Active,
      ratedByPracticeOfficeUserId: this.userInfo()?.sub,
      noShow: item.noShow
    });
    await this.$store.dispatch("Ratings/fetchPracticeOfficeRatings", this.userInfo()?.practiceId);
    this.loading = false;
  }
}
