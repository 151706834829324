export interface IGoogleAddressData {
  administrative_area_level_1: string;
  administrative_area_level_2: string;
  postal_code: string;
  route: string;
  street_number?: string;
  locality: string;
  subpremise?: string;
}

export class UserFormDto {
  id = 0;
  firstName = "";
  lastName = "";
  email = "";
  userName = "";
  phoneNumber = "";
}