
import "./styles/posting-table.scss";
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import { mapState } from "vuex";
import ConfirmedCard from "./cards/confirmed-card.vue";
import OpenCard from "./cards/open-card.vue";
import PendingCard from "./cards/pending-card.vue";
import RequestedCard from "./cards/requested-card.vue";
import AuthenticateMixin from "../authenticate/authenticate.mixin";
import { 
  AcceptedJobPost,
  IOpenJobPost,
  IRequestedJobPost,
  JobPost,
  JobPostGroupResponseStatus,
  JobPostStatus,
  PracticeOfficeModel
} from "./postings.types";
import PostingsMixin from "./postings.mixin";

@Component({
  components: { 
    OpenCard,
    RequestedCard,
    PendingCard,
    ConfirmedCard,
  },
  computed: {
    ...mapState("Postings", 
    [
      "selectedOffice", 
      "openJobs", 
      "requestedJobs", 
      "pendingJobs", 
      "confirmedJobs"
    ])
  }
})
export default class PostingGrid extends Mixins(AuthenticateMixin, PostingsMixin) {
  @Prop() currentUserFilter!: boolean;

  openJobs!: IOpenJobPost[];
  requestedJobs!: IRequestedJobPost[];
  pendingJobs!: AcceptedJobPost[];
  confirmedJobs!: AcceptedJobPost[];
  selectedOffice!: PracticeOfficeModel;

  async created(): Promise<void> {
    this.emitLoading(true);
    
    if (this.selectedOffice.practiceOfficeId){
      await this.fetchJobPostings(this.selectedOffice.practiceOfficeId);
    }
    this.timerRef = setInterval(() => this.fetchJobPostings(this.selectedOffice.practiceOfficeId), 60000);

    this.emitLoading(false);
  }

  destroyed(): void {
    clearInterval(this.timerRef);
  }

  @Watch('selectedOffice')
  async onSelectedOfficeChange(): Promise<void> {
    this.emitLoading(true);

    await this.fetchJobPostings(this.selectedOffice.practiceOfficeId);

    this.emitLoading(false);
  }

  async deleteData(id: number): Promise<void> {
    this.$emit("loading", true);

    await this.$store.dispatch("Postings/setJobPostGroupStatus", {
      jobPostGroupId: id,
      status: JobPostStatus.Deleted
    });
    await this.$store.dispatch("Postings/fetchJobPostings", this.selectedOffice.practiceOfficeId);

    this.$emit("loading", false);
  }

  async pendingData(userId: number, jobPostGroupResponseId: number): Promise<void> {
    this.$emit("loading", true);

    await this.$store.dispatch("Postings/setJobPostGroupResponseStatus", {
      jobPostGroupResponseId,
      userId,
      status: JobPostGroupResponseStatus.Accepted
    });
    await this.$store.dispatch("Postings/fetchJobPostings", this.selectedOffice.practiceOfficeId);

    this.$emit("loading", false);
  }

  getJobPosts(jobPosts: JobPost[]): JobPost[] {
    if (!this.currentUserFilter){
      return jobPosts;
    }

    return jobPosts.filter(jobPost => jobPost.postedByUserId === this.userInfo()?.sub);
  }
}
