
import { Prop, Component, Mixins } from "vue-property-decorator";
import { ISupportDashboardUser } from "./dashboard.types";
import AuthenticateMixin from "../authenticate/authenticate.mixin";

@Component
export default class UserCardComponent extends Mixins(AuthenticateMixin) {
  @Prop() user!: ISupportDashboardUser;

  get initials(): string {
    return this.user.firstName[0] + this.user.lastName[0];
  }

  get fullName(): string {
    return `${this.user.firstName} ${this.user.lastName}`;
  }

  get roleName(): string | undefined {
    if (!this.user.roleName) {
      return "Role not assigned!";
    }
    
    // Adds whitespace before capital letters
    return this.user.roleName.replace(/([A-Z])/g, ' $1').trim(); 
  }

  async onLoginAs(): Promise<void> {
    const userInfo = JSON.stringify(this.userInfo());
    
    if (!userInfo || !this.token) {
      return;
    }

    sessionStorage.setItem("support-user-info", userInfo);
    sessionStorage.setItem("support-token", this.token);

    await this.$store.dispatch("Authenticate/login", { username: this.user.userName });

    window.location.replace("/postings");
  }
}
