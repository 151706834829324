
import { Component, Mixins } from "vue-property-decorator";
import AuthenticateMixin from "../authenticate/authenticate.mixin";
import UserEditModal from "@/common/user/user-edit-modal.vue";
import { IUserInfo } from "../authenticate/authenticate.types";

const basePath = require('@/assets/notification-icons.svg');

@Component({
  components: {
    UserEditModal
  }
})
export default class Header extends Mixins(AuthenticateMixin) {
  modalShow = false;
  currentUser?: IUserInfo = this.userInfo();

  getCustomIconRoute(iconName: string): string {
    return basePath + "#" + iconName;
  }

  get currentUserId(): number | undefined {
    return this.currentUser?.sub;
  }

  get initials(): string {
    if (this.currentUser && this.currentUser?.firstName && this.currentUser?.lastName) {
      return `${this.currentUser?.firstName[0]}${this.currentUser?.lastName[0]}`.toUpperCase();
    }
    
    return "";
  }

  get showBackToDashboard(): boolean {
    const supportUserInfo = sessionStorage.getItem("support-user-info");
    const supportToken = sessionStorage.getItem("support-token");

    return Boolean(supportUserInfo) && Boolean(supportToken);
  }

  logoutPush(): void {
    sessionStorage.removeItem("support-user-info");
    sessionStorage.removeItem("support-token");
    sessionStorage.removeItem("user-info");
    sessionStorage.removeItem("token");
    this.$store.commit("reset");
    this.$router.push("/login");
  }

  backToDashboard(): void {
    const supportUserInfo = sessionStorage.getItem("support-user-info");
    const supportToken = sessionStorage.getItem("support-token");

    if (!supportUserInfo || !supportToken){
      return;
    }

    sessionStorage.removeItem("support-user-info");
    sessionStorage.removeItem("support-token");
    sessionStorage.setItem("user-info", supportUserInfo);
    sessionStorage.setItem("token", supportToken);

    window.location.replace("/customer-support/dashboard");
  }

  showEditProfileModal(): void {
    this.modalShow = true;
  }

  onUserEditSuccess(): void {
    this.currentUser = this.userInfo();
  }
}
