import { getField, updateField } from "vuex-map-fields";
import { VuexModule, Module, Action } from "vuex-module-decorators";
import SupportDashboardService from "./dashboard.service";
import { ISupportDashboardUser } from "./dashboard.types";
import { AxiosError } from "axios";

@Module({ namespaced: true })
class SupportDashboard extends VuexModule {
  users: ISupportDashboardUser[] = [];

  @Action({ rawError: true })
  async getAll(): Promise<void> {
    try {
      const users = await SupportDashboardService.getAll();
      this.context.commit("updateField", {
        path: "users",
        value: users
      });
    } catch (e: unknown) {
      const errorMessage = (e as AxiosError).response?.data.message;
      this.context.commit("updateField", {
        path: "errorMessage",
        value: errorMessage
      });
    }
  }
}

SupportDashboard.getters = { getField };
SupportDashboard.mutations = { updateField };

export default SupportDashboard;