
import { Component, Prop, Mixins } from "vue-property-decorator";
import { ErrorBag, Field } from "vee-validate";
import { mapState } from "vuex";
import { 
  QuickShiftModel,
  JobPostModel,
  PracticeOfficeModel,
  PracticeOfficeHour,
  PracticeOfficeRate,
  Position,
  Positions
} from "../postings.types";
import AuthenticateMixin from "../../authenticate/authenticate.mixin";
import moment from "moment";

@Component({
  computed: {
    ...mapState("Postings", [ "selectedOffice", "positions", "favoritesCount", "practiceOffices" ]),
  },
})
export default class PostShift extends Mixins(AuthenticateMixin) {
  @Prop() modalShow!: boolean;
  @Prop() isGridView!: boolean;

  quickShiftModel: QuickShiftModel = new QuickShiftModel();
  selectedPosition: Position = new Position();
  officeHours: PracticeOfficeHour[] = [];
  officeRates: PracticeOfficeRate[] = [];
  
  selectedOffice!: PracticeOfficeModel;
  practiceOffices!: PracticeOfficeModel[];
  positions!: Position[];
  veeFields!: Field;
  veeErrors!: ErrorBag;
  favoritesCount!: number;
  
  isBusy: boolean = false;

  get postToOptions() {
    var isDisabled = this.favoritesCount == 0;
    return [
      { text: 'All', value: 0, disabled: isDisabled }, 
      { text: 'Favorites Only', value: 1, disabled: isDisabled },
    ];
  }

  get otherFormGroupDisabled() {
    return this.selectedPosition.positionId != Positions.DentalHygienist;
  }

  get postTo() {
    return [this.quickShiftModel.postTo];
  }

  set postTo(value) {
    if (value.length == 0) {
      this.quickShiftModel.postTo = this.quickShiftModel.postTo == 0 ? 1 : 0;
      return;
    }

    var newValue = value[value.length-1];
    if (newValue != this.quickShiftModel.postTo)
      this.quickShiftModel.postTo = newValue;
  }

  get minDate() {
    return new Date();
  }

  get payRate() {
    return this.quickShiftModel.payRate?.toFixed(2);
  }

  async onOfficeSelect(value: PracticeOfficeModel): Promise<void> {
    this.$store.commit("Postings/updateField", {
      path: "selectedOffice",
      value: value
    });
  }

  setPayRate(value: string | undefined) {
    if (value) 
      this.quickShiftModel.payRate = parseFloat(value);
  }

  async mounted() {
    this.isBusy = true;
    this.officeHours = await this.$store.dispatch("Postings/fetchPracticeOfficeHours", 
      this.selectedOffice.practiceOfficeId);
    this.officeRates = await this.$store.dispatch("Postings/fetchPracticeOfficeDefaultRates", 
      this.selectedOffice.practiceOfficeId);
    await this.fetchData();
    this.isBusy = false;
  }

  onChange(date: Date) {
    const dow = moment(date).isoWeekday() % 7 + 1;
    const officeHours = this.officeHours.find(obj => {
      return obj.dayOfWeek === dow
    });
    this.quickShiftModel.timeIn = officeHours?.openTime == null ? "08:00" : officeHours.openTime;
    this.quickShiftModel.timeOut = officeHours?.closeTime == null ? "17:00" : officeHours.closeTime;
    this.quickShiftModel.breakTime = officeHours?.breakMinutes == null ? 0 : officeHours.breakMinutes;
  }

  validateState(ref: string) {
    if (
      this.veeFields[ref as keyof Field] &&
      (this.veeFields[ref as keyof Field].dirty || 
      this.veeFields[ref as keyof Field].validated)
    ) {
      return !this.veeErrors.has(ref);
    }
    return null;
  }

  async fetchData() {
    await this.$store.dispatch("Postings/fetchPositions");
    await this.$store.dispatch("Postings/fetchPostShiftDetails");
  }

  createJobPost() {
    this.$validator.validateAll().then(async (isValid: boolean) => {
      if (isValid) {
        const date = this.quickShiftModel.date.replaceAll('-', '_');
        const time = this.quickShiftModel.timeIn.replaceAll(':', '_');
        const jobPost : JobPostModel = {
          practiceOfficeId: this.selectedOffice.practiceOfficeId,
          termsLookUpId: 57, // hardcoded for now
          groupName: `GRP_${date}_${time}_${this.selectedPosition.positionId}`,
          payRate: this.quickShiftModel.payRate!,
          positionId: this.selectedPosition.positionId,
          favoritesOnly: this.quickShiftModel.postTo == 1,
          postDate: moment().format("YYYY-MM-DD"),
          breakTime: this.quickShiftModel.breakTime!,
          removeByDate: null, // hardcoded for now
          startDate: `${this.quickShiftModel.date}T${this.quickShiftModel.timeIn}`,
          endDate: `${this.quickShiftModel.date}T${this.quickShiftModel.timeOut}`,
          speakSpanish: this.quickShiftModel.speakSpanish,
          doubleChannel: this.quickShiftModel.channel == 2,
          postedByUserId: this.userInfo()?.sub ?? 0
        }
        await this.$store.dispatch("Postings/postShift", jobPost),
        this.$emit('reload');
        this.$emit('update:modalShow', false);
      }
    });
  }

  onPositionSelect(value: Position) {
    const officeRates = this.officeRates.find(obj => {
      return obj.positionId === value.positionId
    });
    this.quickShiftModel.payRate = officeRates?.amount ?? null;
    this.selectedPosition = value;
    if (value.positionId != Positions.DentalHygienist) {
      this.quickShiftModel.channel = null;
    }
  }

  closeModal() {
    this.$emit('update:modalShow', false);
  }
}
