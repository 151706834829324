
import { Component, Prop, Vue } from 'vue-property-decorator';

interface IPopoverRef extends Element {
  doClose(): void;
}

@Component
export default class FilterPopover extends Vue {
  @Prop() target!: string;
  @Prop() tableData!: Record<string, unknown>[];
  @Prop() filters!: Record<string, unknown[]>;
  @Prop() text?: string;
  @Prop() value!: string;

  valueKey!: string;
  textKey!: string;

  created(): void {
    this.valueKey = this.value;

    if (this.text) {
      this.textKey = this.text;
    }
    else {
      this.textKey = this.valueKey;
    }
  }

  getFilterOptions(): { text: string, value: string }[] {
    return this.getUniqueTableValues()
      .map(item => 
        ({
          text: item[this.textKey] as string,
          value: item[this.valueKey] as string
        })
      );
  }

  getUniqueTableValues(): Record<string, unknown>[] {
    return [
      ...new Map(
        this.tableData
          .map(item => [item[this.valueKey], item])
      ).values()
    ].filter(item => item[this.valueKey])
  }

  onFilterClear(): void {
    if (!this.valueKey){
      return;
    }

    this.filters[this.valueKey] = [];
    (this.$refs[this.target] as IPopoverRef).doClose();
  }
}
