
import { Component, Vue } from "vue-property-decorator";
import { mapState } from "vuex";
import UserCard from "./user-card.vue";

@Component({
  components: {
    UserCard
  },
  computed: {
    ...mapState("SupportDashboard", ["users"])
  }
})
export default class DashboardComponent extends Vue {
  loading = false;

  async mounted(): Promise<void> {
    this.loading = true;

    await this.$store.dispatch("SupportDashboard/getAll");

    this.loading = false;
  }
}
