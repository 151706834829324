import { RouteConfig } from "vue-router";
import DashboardComponent from "./dashboard.vue";
import { Roles } from "@/views/authenticate/authenticate.types";

export const CUSTOMER_SUPPORT_ROUTES: RouteConfig[] = [
  {
    path: "/customer-support/dashboard",
    component: DashboardComponent,
    meta: {
      roles: [Roles.CustomerSupport],
      nav: true,
      navTitle: "Dashboard",
      navIcon: "fa-users"
    }
  }
];