import { Component, Vue } from "vue-property-decorator";
import { mapState } from "vuex";
import { 
  OfficeProfile,
  OfficeProfileHours
} from "./office-profiles.types";

@Component({
  computed: {
    ...mapState("OfficeProfiles", [
      "selectedOfficeProfile", 
      "officeProfiles",
      "errorMessage",
      "isEdit",
    ])
  }
})
export default class OfficeProfileMixin extends Vue {
  selectedOfficeProfile!: OfficeProfile;
  officeProfiles!: OfficeProfile[];
  errorMessage!: string;
  isEdit!: boolean;

  getOpenTime(day: string, hours: OfficeProfileHours[]) {
    const officeHour = hours.filter(x => x.day == day)[0];
    return officeHour.start == "" ? null : officeHour.start;
  }

  getCloseTime(day: string, hours: OfficeProfileHours[]) {
    const officeHour = hours.filter(x => x.day == day)[0];
    return officeHour.end == "" ? null : officeHour.end;
  }

  getBreakMinutes(day: string, hours: OfficeProfileHours[]) {
    const officeHour = hours.filter(x => x.day == day)[0];
    return officeHour.break == "" ? null : officeHour.break;
  }
}