import Vue from 'vue';
import Vuex from 'vuex';
import Auth from "./modules/auth.module";
import Base from "@/views/base/base.module";
import Postings from "@/views/postings/postings.module";
import Ratings from "@/views/ratings/ratings.module";
import Authenticate from "@/views/authenticate/authenticate.module";
import OfficeProfiles from "@/views/office-profiles/office-profiles.module";
import Notifications from "@/views/notifications/notifications.module";
import Schedulers from "@/views/schedulers/schedulers.module";
import Wizard from "@/views/wizard/wizard.module";
import DentalTemps from "@/views/dental-temps/dental-temps.module";
import SupportDashboard from '@/views/customer-support/dashboard.module';
import User from '@/store/modules/user.module';
import Vacancy from '@/views/vacancies/vacancies-module';

Vue.use(Vuex)

const initialState: any = JSON.parse(JSON.stringify({
  Auth: Auth.state,
  Base: Base.state,
  Postings: Postings.state,
  Ratings: Ratings.state,
  Authenticate: Authenticate.state,
  OfficeProfiles: OfficeProfiles.state,
  Notifications: Notifications.state,
  Schedulers: Schedulers.state,
  Wizard: Wizard.state,
  DentalTemps: DentalTemps.state,
  SupportDashboard: SupportDashboard.state,
  User: User.state,
  Vacancy: Vacancy.state
}));

export default new Vuex.Store({
  state: {},
  actions: {},
  modules: {
    Auth,
    Base,
    Postings,
    Ratings,
    Authenticate,
    OfficeProfiles,
    Notifications,
    Schedulers,
    Wizard,
    DentalTemps,
    SupportDashboard,
    User,
    Vacancy
  },
  
  mutations: {
    reset (state) {
      Object.keys(state).forEach(key => {
        Object.assign(state[key], initialState[key])
      })
    }
  }
})
