const generateHexColor = function(rgbRange = 201): string {
    const red = Math.floor(Math.random() * rgbRange).toString(16);
    const green = Math.floor(Math.random() * rgbRange).toString(16);
    const blue = Math.floor(Math.random() * rgbRange).toString(16);

    const pad = 0;
    const paddedRed = red.length < 2 ? pad + red : red;
    const paddedGreen = green.length < 2 ? pad + green : green;
    const paddedBlue = blue.length < 2 ? pad + blue : blue;

    return "#" + paddedRed + paddedGreen + paddedBlue;
}

export { generateHexColor }