
import { Component, Mixins } from "vue-property-decorator";
import { InformationFormDto, SchedulerFormDto, WizardStep } from "../wizard.types";
import WizardMixin from "../wizard.mixin";
import { mapState } from "vuex";

@Component({
  computed: {
    ...mapState('Wizard', { 
      infoForm: 'infoForm',
      form: 'schedulerForm',
      isLoading: 'isLoading',
      errorMessage: 'errorMessage'
    })
  }
})
export default class WizardSchedulerComponent extends Mixins(WizardMixin) {
  errorMessage!: string;
  infoForm!: InformationFormDto;
  form!: SchedulerFormDto | null;
  WizardStep = WizardStep;

  get isFormRequired(): boolean {
    return Boolean(this.form?.firstName) ||
      Boolean(this.form?.lastName) ||
      Boolean(this.form?.email) ||
      Boolean(this.form?.cellPhone);
  }

  created(): void {
    if (!this.form){
      this.$store.commit('Wizard/updateField', {
        path: "schedulerForm",
        value: new SchedulerFormDto()
      });
    }
  }

  async finishForm(): Promise<void> {
    if (!this.isFormRequired) {
      this.$store.commit('Wizard/updateField', {
        path: "schedulerForm",
        value: null
      });

      this.next(WizardStep.Hours);

      return;
    }

    await this.$store.dispatch("Wizard/validateUsername", this.form?.email);

    this.$validator.validateAll().then((isValid: boolean) => {
      if (isValid && !this.errorMessage) {
        this.$validator.reset();
        
        this.next(WizardStep.Hours);
      }
    });
  }
}
