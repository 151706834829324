
import { Component, Mixins, Ref } from "vue-property-decorator";
import AuthenticateMixin from "../authenticate/authenticate.mixin";
import OfficeProfilesMixin from "./office-profiles.mixin";
import OfficeProfilesCard from "./office-profiles-card.vue";
import { mapState } from "vuex";
import { 
  OfficeProfile
} from "./office-profiles.types";

@Component({
  components: {
    OfficeProfilesCard,
  },
  computed: {
    ...mapState("OfficeProfiles", ["practiceName"])
  }
})
export default class OfficeProfilesComponent extends Mixins(OfficeProfilesMixin, AuthenticateMixin) {
  loading = false;
  newPracticeName: string = "";
  practiceName!: string;
  isPracticeEdit: boolean = false;
  @Ref() practiceNameElement!: HTMLInputElement;

  get isPracticeNameValid() {
    return this.newPracticeName != null && 
           this.newPracticeName.length > 0 && 
           this.newPracticeName.length < 256;
  }

  async mounted() {
    await this.fetchData();
  }

  async fetchData() {
    this.loading = true;
    await this.$store.dispatch("OfficeProfiles/fetchOfficeProfiles", this.userInfo()?.practiceId);
    this.loading = false;
  }

  edit() {
    this.newPracticeName = this.practiceName;
    this.isPracticeEdit = true;
    this.$nextTick(() => this.practiceNameElement.focus());
  }

  async save() {
    if (!this.isPracticeNameValid)
      return;
    await this.$store.dispatch("OfficeProfiles/updatePractice", { practiceId: this.userInfo()?.practiceId, name: this.newPracticeName});
    this.isPracticeEdit = false;
  }

  addOfficeProfile() {
    this.$store.commit("OfficeProfiles/updateField", {
      path: "selectedOfficeProfile",
      value: new OfficeProfile()
    });
    this.$store.commit("OfficeProfiles/updateField", {
      path: "isEdit",
      value: false
    });
    this.$router.push("/office-profiles-edit");
  }
}
