
import { Component, Mixins } from "vue-property-decorator";
import AuthenticateMixin from "../authenticate/authenticate.mixin";
import SchedulersCard from "./schedulers-card.vue";
import SchedulersNewUser from "./schedulers-new-user.vue";
import { mapState } from "vuex";
import { PracticeUser } from "./schedulers.types";

@Component({
  components: {
    SchedulersCard,
    SchedulersNewUser
  },
  computed: {
    ...mapState("Schedulers", ["practiceUsers"])
  }
})
export default class SchedulersComponent extends Mixins(AuthenticateMixin) {
  practiceUsers!:  PracticeUser[];
  modalShow = false;
  loading = false;
  search = "";

  async mounted() {
    await this.fetchData();
  }

  get filteredUsers() {
    const list = [...this.practiceUsers];
    return list?.filter((user) => {
      return user.name.toLowerCase().includes(this.search.toLowerCase());
    });
  }

  async fetchData() {
    this.loading = true;
    const fetch = [
      this.$store.dispatch("Schedulers/fetchPracticeUsers", this.userInfo()?.practiceId),
      this.$store.dispatch("Schedulers/fetchPracticeOffices", this.userInfo()?.practiceId)
    ];
    await Promise.all(fetch);
    this.loading = false;
  }

  onClick() {
    this.modalShow = true;
  }

  async reload() {
    await this.fetchData();
  }
}
