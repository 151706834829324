import { Component, Vue } from "vue-property-decorator";
import { IGoogleAddressData } from "@/common/types";
import { ErrorBag, Field } from "vee-validate";
import { IAddress } from "@/views/office-profiles/office-profiles.types";

interface IGoogleAddressRef extends Element {
  update(addressString: string): void;
}

@Component
export default class GoogleAddressMixin extends Vue {
  addressString = "";

  getAddressClassName(veeFields: Field, veeErrors: ErrorBag): string {
    let classname = "form-control form-control-sm";
    const addressKey: keyof Field = "input-address" as keyof Field;
    const validClass = "is-valid";
    const invalidClass= "is-invalid";

    if (veeFields[addressKey] && (veeFields[addressKey].dirty || veeFields[addressKey].validated)) {
      if (veeErrors.has(addressKey)) {
        classname += " " + invalidClass;
      }

      classname += " " + validClass;
    }

    return classname;
  }

  setAddressData(address: IAddress, data: IGoogleAddressData): void {
    if (data.street_number) {
      address.address1 = data.street_number + " " + data.route;
    }
    else {
      address.address1 = data.route;
    }

    address.address2 = data.subpremise ?? "";
    address.city = data.locality;
    address.state = data.administrative_area_level_1;
    address.zipCode = data.postal_code;

    this.setAddressString(address);
  }

  onAddressChange(value: string): void {
    this.addressString = value;
  }

  resetAddress(address: IAddress): void {
    address.address1 = "";
    address.address2 = null;
    address.city = "";
    address.state = "";
    address.zipCode = "";
  }

  setAddressString(address: IAddress): void {
    this.addressString = address.address1 + " " + address.address2 + ", " + address.city + ", " + address.state;
  }

  getAddressString(): string {
    const addressRef = this.$refs.address as IGoogleAddressRef;
    
    if (addressRef) {
      addressRef.update(this.addressString);
    }
  
    return this.addressString;
  }
}