import { RouteConfig } from "vue-router";
import SchedulersComponent from "@/views/schedulers/schedulers.vue";
import { Roles } from "@/views/authenticate/authenticate.types";

export const SCHEDULERS_ROUTES: RouteConfig[] = [
  {
    path: "/schedulers",
    name: "SchedulersComponent",
    component: SchedulersComponent,
    meta: {
      roles: [Roles.PracticeUser, Roles.OfficeUser],
    }
  }
];