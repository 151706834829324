const toOptions = function(
  value: Record<string, unknown>,
  textOverrides?: { text: string, value: number }[]
): { text: string, value: number }[] {
  return Object.keys(value)
    .filter(item => isNaN(Number(item)) === false)
    .map((item: string) => ({
      text: textOverrides?.some(override => override.value === parseInt(item)) ? 
        textOverrides.find(override => override.value === parseInt(item))?.text ?? ''
        :
        (value[item] as string).replace(/([A-Z])/g, ' $1').trim(),
      value: parseInt(item)
    }));
};

export default { toOptions };