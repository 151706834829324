
import { Component, Mixins } from "vue-property-decorator";
import { InformationFormDto, WizardStep } from "../wizard.types";
import WizardMixin from "@/views/wizard/wizard.mixin";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import GoogleAddressMixin from '@/common/address/google-address.mixin';
import { mapState } from "vuex";

@Component({
  components: {
    VueGoogleAutocomplete
  },
  computed: {
    ...mapState('Wizard', { form: 'infoForm' })
  }
})
export default class WizardInformationComponent extends Mixins(WizardMixin, GoogleAddressMixin) {
  form!: InformationFormDto;
  WizardStep = WizardStep;

  mounted(): void {
    if (this.form.address1){
      this.setAddressString(this.form);
    }
  }

  finishForm(): void {
    this.$validator.validateAll().then((isValid: boolean) => {
      if (isValid) {
        this.$validator.reset();
        
        this.next(WizardStep.Schedulers);
      }
    });
  }
}
