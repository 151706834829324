import httpAuth from "@/common/http/http-common";
import { ISupportDashboardUser } from "./dashboard.types";

class SupportDashboardService {
  async getAll(): Promise<ISupportDashboardUser[]> {
    const { data } = await httpAuth().get("User/GetAll");
    return data;
  }
}

export default new SupportDashboardService();