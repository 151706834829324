
import { Component, Mixins } from "vue-property-decorator";
import { mapState } from "vuex";
import AuthenticateMixin from "../authenticate/authenticate.mixin";
import { IVacancyDto } from "./vacancies-types";
import VacancyCard from "./vacancy-card.vue";
import VacancyCreateEditModal from "./vacancy-create-edit-modal.vue";
import VacancyApplicantModal from "./vacancy-applicant-modal.vue";

const basePath = require('@/assets/notification-icons.svg');

@Component({
  components: {
    VacancyCard,
    VacancyCreateEditModal,
    VacancyApplicantModal
  },
  computed: {
    ...mapState("Vacancy", ["vacancies"])
  }
})
export default class VacancyComponent extends Mixins(AuthenticateMixin) {
  vacancies!: IVacancyDto[];
  selectedId = 0;
  loading = true;

  async created(): Promise<void> {
    this.loading = true;
    await this.getData();
    this.loading = false;
  }

  async getData(): Promise<void> {
    await this.$store.dispatch("Postings/fetchPracticeOffices", this.userInfo()?.sub);
    await this.$store.dispatch("Vacancy/get", this.userInfo()?.practiceId);
  }

  async showApplicantModal(id: number): Promise<void> {
    this.loading = true;
    await this.$store.dispatch("Vacancy/getDetails", { id, practiceId: this.userInfo()?.practiceId });
    this.$bvModal.show("vacancy-applicant-modal");
    this.loading = false;
  }

  async fill(vacancyId: number): Promise<void> {
    this.loading = true;
    await this.$store.dispatch("Vacancy/toggleFill", vacancyId);
    await this.getData();
    this.loading = false;
  }

  async repost(vacancyId: number): Promise<void> {
    this.loading = true;
    await this.$store.dispatch("Vacancy/repost", vacancyId);
    await this.getData();
    this.loading = false;
  }

  showCreateModal(): void {
    this.selectedId = 0;
    this.$store.dispatch("Vacancy/resetForm");
    this.$bvModal.show("vacancy-create-edit-modal");
  }

  showEditModal(id: number): void {
    this.selectedId = id;
    this.$bvModal.show("vacancy-create-edit-modal");
  }

  showDeleteModal(id: number): void {
    this.selectedId = id;
    this.$bvModal.show("vacancy-delete-modal");
  }

  async deleteVacancy(): Promise<void> {
    this.loading = true;
    await this.$store.dispatch("Vacancy/delete", this.selectedId);
    await this.getData();
    this.loading = false;
  }

  getCustomIconRoute(iconName: string): string {
    return basePath + "#" + iconName;
  }
}
