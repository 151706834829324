
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { mapState } from 'vuex';
import { PracticeOfficeModel } from '@/views/authenticate/authenticate.types';
import { ISelectOption, VacancyFormDataDto, VacancyFormDto } from './vacancies-types';
import VacancyCheckbox from "./vacancy-checkbox.vue";
import { VueEditor } from "vue2-editor";
import { ErrorBag, Field } from 'vee-validate';
import AuthenticateMixin from '../authenticate/authenticate.mixin';

@Component({
  components: {
    VacancyCheckbox,
    VueEditor
  },
  computed: {
    ...mapState("Postings", {
      selectedOffice: "selectedOffice", 
      practiceOffices: "practiceOffices"
    }),
    ...mapState("Vacancy", {
      formData: "vacancyFormData",
      form: "vacancyForm"
    })
  }
})
export default class VacancyCreateEditComponent extends Mixins(AuthenticateMixin) {
  @Prop() id!: number;

  practiceOffices!: PracticeOfficeModel[];
  selectedOffice!: PracticeOfficeModel;
  formData!: VacancyFormDataDto;
  form!: VacancyFormDto;

  estimatedSalaryType: number | null = null;

  veeFields!: Field;
  veeErrors!: ErrorBag;

  loading = true;
  editorToolbar = [
    [{ header: [false, 1, 2, 3, 4, 5, 6] }],
    ["bold", "italic", "underline", "strike"],
    [
      { align: "" },
      { align: "center" },
      { align: "right" },
      { align: "justify" }
    ],
    [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
    [{ indent: "-1" }, { indent: "+1" }],
    [{ color: [] }, { background: [] }],
    ["link", "image", "video"],
    ["clean"]
  ];

  get schedulers(): ISelectOption[] {
    return this.formData.schedulers.filter(scheduler => parseInt(scheduler.identifier) === this.selectedOffice.practiceOfficeId);
  }

  validateField(ref: keyof Field): void {
    this.$validator.validate(ref);
  }

  hasError(ref: keyof Field): boolean | null {
    if (
      this.veeFields[ref] &&
      (this.veeFields[ref].dirty || 
      this.veeFields[ref].validated)
    ) {
      return this.veeErrors.has(ref);
    }
    return null;
  }

  async onShow(): Promise<void> {
    await this.$store.dispatch("Vacancy/getFormData", this.userInfo()?.practiceId);
    this.estimatedSalaryType = null;
    this.form.schedulerIds = this.schedulers.map((option: ISelectOption) => option.value);

    if (this.id) {
      await this.$store.dispatch("Vacancy/getForm", this.id);

      this.$store.commit("Postings/updateField", {
        path: "selectedOffice",
        value: this.practiceOffices.find(office => office.practiceOfficeId === this.form.practiceOfficeId)
      });

      if (this.form.estimatedSalary) {
        this.estimatedSalaryType = this.formData.estimatedSalaries.find(salary => salary.identifier === "Static")?.value ?? null;
      }

      if (this.form.estimatedSalaryMin) {
        this.estimatedSalaryType = this.formData.estimatedSalaries.find(salary => salary.identifier === "Range")?.value ?? null;
      }

      if (this.form.estimatedSalaryHourly) {
        this.estimatedSalaryType = this.formData.estimatedSalaries.find(salary => salary.identifier === "Hourly")?.value ?? null;
      }
    }

    this.loading = false;
  }

  onClose(): void {
    this.loading = true;
  }

  closeModal(): void {
    this.$bvModal.hide('vacancy-create-edit-modal');
  }

  normalizeEstimatedSalaries(): void {
    switch(this.estimatedSalaryType) {
      case this.formData.estimatedSalaries.find(salary => salary.identifier === "Static")?.value:
        this.form.estimatedSalaryMin = null;
        this.form.estimatedSalaryMax = null;
        this.form.estimatedSalaryHourly = null;
        if (this.form.estimatedSalary) {
          this.form.estimatedSalary = Math.round(this.form.estimatedSalary);
        }
        break;
      case this.formData.estimatedSalaries.find(salary => salary.identifier === "Range")?.value:
        this.form.estimatedSalaryHourly = null;
        this.form.estimatedSalary = null;
        if (this.form.estimatedSalaryMin && this.form.estimatedSalaryMax){
          this.form.estimatedSalaryMin = Math.round(this.form.estimatedSalaryMin);
          this.form.estimatedSalaryMax = Math.round(this.form.estimatedSalaryMax);
        }
        break;
      case this.formData.estimatedSalaries.find(salary => salary.identifier === "Hourly")?.value:
        this.form.estimatedSalaryMin = null;
        this.form.estimatedSalaryMax = null;
        this.form.estimatedSalary = null;
        if (this.form.estimatedSalaryHourly) {
          this.form.estimatedSalaryHourly = Math.round(this.form.estimatedSalaryHourly * 100) / 100;
        }
        break;
      default:
        this.form.estimatedSalaryMin = null;
        this.form.estimatedSalaryMax = null;
        this.form.estimatedSalary = null;
        this.form.estimatedSalaryHourly = null;
    }
  }

  async onSubmit(): Promise<void> {
    this.$validator.validateAll().then(async (isValid: boolean) => {
      if (isValid) {
        this.loading = true;
        try {
          this.form.practiceOfficeId = this.selectedOffice.practiceOfficeId;

          const signingBonusId = this.formData.supplementalPays.find(pay => pay.identifier === "SigningBonus")?.value;

          if (this.form.supplementalPays.some(pay => pay === signingBonusId) && this.form.signingBonus) {
            this.form.signingBonus = Math.round(this.form.signingBonus);
          }
          else {
            this.form.signingBonus = null;
          }

          this.normalizeEstimatedSalaries();

          await this.$store.dispatch("Vacancy/save", this.form);
        }
        catch {
          return;
        }
        this.$emit("success");
        this.closeModal();
      }
    });
  }

  onOfficeSelect(value: PracticeOfficeModel): void {
    this.$store.commit("Postings/updateField", {
      path: "selectedOffice",
      value: value
    });
  }
}
