
import { Component, Mixins } from "vue-property-decorator";
import AuthenticateMixin from "../../authenticate/authenticate.mixin";
import WizardMixin from "../wizard.mixin";
import Header from "../header.vue";
import { WizardStep } from "../wizard.types";

@Component({
  components: {
    Header
  }
})
export default class WizardCompleteComponent extends Mixins(WizardMixin, AuthenticateMixin) {
  WizardStep = WizardStep;
}
