import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import router from '@/router';

export default ({ requiresAuth = true } = {}): AxiosInstance => {
  const options: AxiosRequestConfig = {};
  const link = process.env.VUE_APP_API_URL;
  const token = sessionStorage.getItem("token");

  options.baseURL = link;
  options.headers = {
    "Content-type": "application/json",
    Access: "application/json"
  };

  if (requiresAuth) {
    options.headers.Authorization = "Bearer " + token;
  }

  const instance = axios.create(options);

  instance.interceptors.response.use(
    res => res,
    err => {
      if (err.response.status === 401) {
        router.push({ path: '/login'});
      }
      return Promise.reject(err);
    }
  );
  return instance;
};