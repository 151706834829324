export interface IAccessTokenData {
  name: string;
  role: string;
  exp: number;
  iat: number;
  jti: number;
  nbf: number;
}

export interface IUserInfo {
  sub: number;
  email: string;
  firstName: string;
  lastName: string;
  role: string;
  practiceId?: number;
  isWizardComplete: boolean;
}

export class LoginModel {
  firstName: string | null = null;
  lastName: string | null = null;
  id: number | null = null;
  token: string | null = null;
  userName: string | null = null;
  role: string | null = null;
}

export class LoginDto {
  username: string = "";
  password: string | undefined;
}

export class NewPasswordDto {
  token: string | null = null;
  email: string = "";
  password: string = "";
  confirm: string  = "";
}

export class RegisterDto {
  firstName: string | null = null;
  lastName: string | null = null;
  email: string | null = null;
  password: string | null = null;
  confirm: string | null = null;
  phoneNumber: string | null = null;
}

export class PracticeOfficeModel {
  practiceOfficeId: number = 0;
  officeName: string = "";
  address1: string = "";
  address2: string = "";
  city: string = "";
  state: string = "";
  zipCode: string = "";
  phone: string = "";
  emailAddress: string = "";
  eoPayment: boolean | null = false;
  website: string = "";
  image: string = "";
  practiceId: number = 0;
  practiceName: string = "";
}

export abstract class Roles {
  static DentalStaff = "DentalStaff";
  static OfficeUser = "OfficeUser";
  static PracticeUser = "PracticeUser";
  static CustomerSupport = "CustomerSupport";
}

export enum ErrorType {
  Misc,
  Confirmation,
  Credentials
}