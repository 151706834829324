
import { Component, Mixins } from "vue-property-decorator";
import NotificationsCard from "./notifications-card.vue";
import NotificationsMixin from "./notifications.mixin";
import AuthenticateMixin from "../authenticate/authenticate.mixin";
import { 
  PracticeOfficeNotification,
  NotificationStatus
} from "./notifications.types";

@Component({
  components: {
    NotificationsCard,
  }}
)
export default class NotificationsComponent extends Mixins(NotificationsMixin, AuthenticateMixin) {
  loading = false;

  async mounted() {
    await this.fetchData();
  }

  async fetchData() {
    this.loading = true;
    await this.$store.dispatch("Notifications/fetchNotifications", this.userInfo()?.sub);
    this.loading = false;
  }

  async onDelete(notification: PracticeOfficeNotification) {
    this.loading = true;
    await this.$store.dispatch("Notifications/setPracticeOfficeNotificationStatus", {
      practiceOfficeNotificationId: notification.practiceOfficeNotificationId,
      status: NotificationStatus.Inactive
    });
    await this.$store.dispatch("Notifications/fetchNotifications", this.userInfo()?.sub);
    this.loading = false;
  }
}
