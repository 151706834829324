import { getField, updateField } from "vuex-map-fields";
import { VuexModule, Module, Action } from "vuex-module-decorators";
import NotificationsService from "./notifications.service";
import { 
  PracticeOfficeNotification,
  NotificationStatusDto
} from "./notifications.types";

@Module({ namespaced: true })
class Notifications extends VuexModule {
  notifications: PracticeOfficeNotification[] = [];

  @Action({ rawError: true })
  async fetchNotifications(userId: number) {
    try {
      const notifications = await NotificationsService.getOfficeNotifications(
        userId
      ) as PracticeOfficeNotification[];
      this.context.commit("updateField", {
        path: "notifications",
        value: notifications
      });
      return notifications;
    } catch (e) {
      console.error(e);
    }
  }
  
  @Action({ rawError: true })
  async setPracticeOfficeNotificationStatus(dto: NotificationStatusDto) {
    try {
      await NotificationsService.setPracticeOfficeNotificationStatus(dto);
    } catch (e) {
      console.error(e);
    }
  }
}

Notifications.getters = { getField };
Notifications.mutations = { updateField };

export default Notifications;