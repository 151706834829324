
    import { Component, Mixins } from "vue-property-decorator";
    import AuthenticateMixin from "./authenticate.mixin";
    import { ErrorBag, Field } from "vee-validate";
    import { mapState } from "vuex";
    
    @Component({
      computed: {
        ...mapState("Authenticate", ["errorMessage"])
      }
    })
    export default class ResetPassword extends Mixins(AuthenticateMixin) {
      loading = false;
      username: string = "";
    
      veeFields!: Field;
      veeErrors!: ErrorBag;
      errorMessage!: string;
    
      validateState(ref: keyof Field) {
        if (
          this.veeFields[ref] &&
          (this.veeFields[ref].dirty || 
          this.veeFields[ref].validated)
        ) {
          return !this.veeErrors.has(ref);
        }
        return null;
      }

      closeModal() {
        this.$router.push("/login");
      }
    
      reset() {
        this.$validator.validateAll().then(async (isValid: boolean) => {
          if (isValid) {
            this.loading = true;
            var result = await this.$store.dispatch("Authenticate/requestPasswordReset", this.username);
            if (result) {
              alert(result);
              this.closeModal();
            }
            this.loading = false;
          }   
        });
      }
    }
