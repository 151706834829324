import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import VeeValidate from "vee-validate";
import router from './router'
import store from './store'
import {
  BootstrapVue,
  IconsPlugin,
  LayoutPlugin,
  FormCheckboxPlugin,
  ToastPlugin
} from "bootstrap-vue";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import "vue2-editor/dist/vue2-editor.css"
import Multiselect from 'vue-multiselect';
import moment from "moment";
import { IAddress } from './views/office-profiles/office-profiles.types';

Vue.config.productionTip = false;
Vue.prototype.moment = moment;
const VueInputMask = require('vue-inputmask').default;

Vue.use(IconsPlugin);
Vue.use(BootstrapVue);
Vue.use(LayoutPlugin);
Vue.use(FormCheckboxPlugin);
Vue.use(ToastPlugin);
Vue.use(VueInputMask);
Vue.use(Loading);

Vue.use(VeeValidate, {
  inject: true,
  fieldsBagName: "veeFields",
  errorBagName: "veeErrors",
  events: 'change|blur'
});

VeeValidate.Validator.extend('verify_password', {
  getMessage: field => `The password must contain at least: 1 uppercase letter, 1 lowercase letter, 1 number, and one special character`,
  validate: value => {
    var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
    return strongRegex.test(value);
  }
});

VeeValidate.Validator.extend('verify_phone', {
  getMessage: field => `The phone number is invalid.`,
  validate: value => {
    var strongRegex = new RegExp("^\\d{10}$");
    return strongRegex.test(value);
  }
});

VeeValidate.Validator.extend('verify_zip', {
  getMessage: field => `The zip code is invalid.`,
  validate: value => {
    var strongRegex = new RegExp("^[0-9]{5}$");
    return strongRegex.test(value);
  }
});

VeeValidate.Validator.extend('verify_rate', {
  getMessage: field => `The rate is invalid.`,
  validate: value => {
    var strongRegex = new RegExp("^[0-9]{1,3}([.][0-9]{0,2})?$");
    return strongRegex.test(value);
  }
});

VeeValidate.Validator.extend('verify_breaktime', {
  getMessage: field => `The breaktime is invalid.`,
  validate: value => {
    var strongRegex = new RegExp("^([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])$");
    return strongRegex.test(value);
  }
});

VeeValidate.Validator.extend('verify_time_start', {
  getMessage: field => `Close hours should be after Open hours.`,
  paramNames: ['end'],
  validate: (value, p: any) => {
    return value < p.end;
  }
}, { hasTarget: true });

VeeValidate.Validator.extend('verify_time_end', {
  getMessage: field => `Close hours should be after Open hours.`,
  paramNames: ['start'],
  validate: (value, p: any) => {
    return value > p.start;
  }
}, {hasTarget: true});


VeeValidate.Validator.extend('before_time', {
  getMessage: field => `The time_in field must be before the time_out field.`,
  validate: (value, otherValue: any) => {
    var start = moment(value, 'HH:mm');
    var end = moment(otherValue, 'HH:mm');
    return start <= end;
  }}, {
  hasTarget: true
});

VeeValidate.Validator.extend('address', {
  getMessage: () => 'Address is invalid.',
  validate: (_: string, value: unknown) => {
    const address = value as IAddress;
    return Boolean(address) &&
      Boolean(address.address1) &&
      Boolean(address.city) &&
      Boolean(address.state) &&
      Boolean(address.zipCode);
  }
});

VeeValidate.Validator.extend('monetary_range', {
  getMessage: () => 'Monetary range is invalid.',
  validate: (value: number, arr: unknown) => {
    const values = arr as string[];
    return Boolean(arr) && value < parseInt(values[0]);
  }
});

VeeValidate.Validator.extend('monetary', {
  getMessage: () => 'Monetary value is invalid.',
  validate: (_: number, otherValue: unknown) => {
    return (otherValue as number[])[0] > 0;
  }
});

Vue.component("loading", Loading);
Vue.component('multiselect', Multiselect);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
