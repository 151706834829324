
import { Component, Mixins } from "vue-property-decorator";
import { ErrorBag, Field } from "vee-validate";
import { OfficeProfilePayrate } from "./office-profiles.types";
import { Position } from "@/views/postings/postings.types";
import AuthenticateMixin from "../authenticate/authenticate.mixin";
import OfficeProfilesMixin from "./office-profiles.mixin";

@Component
export default class OfficeRatesEditComponent extends Mixins(OfficeProfilesMixin, AuthenticateMixin) {
  officeRates: OfficeProfilePayrate[] = [];
  loading = false;
  veeFields!: Field;
  veeErrors!: ErrorBag;

  async mounted() {
    const positions = await this.$store.dispatch("OfficeProfiles/fetchPositions") as Position[];
    positions.sort((a: Position, b: Position) => a.sortOrder - b.sortOrder);
    const officeRates = [];
    for (var position of positions) {
      officeRates.push({
        position: position.name,
        rate: 0,
        positionId: position.positionId,
        practiceOfficeId: 0
      });
    }
    this.officeRates = [ ... this.isEdit ? this.selectedOfficeProfile.payrates : officeRates ];
    this.$validator.reset();
  }

  validateState(ref: keyof Field) {
    if (
      this.veeFields[ref] &&
      (this.veeFields[ref].dirty || 
      this.veeFields[ref].validated)
    ) {
      return !this.veeErrors.has(ref);
    }
    return null;
  }

  async update() {
    this.$validator.validateAll().then(async (isValid: boolean) => {
      if (isValid) {
        this.loading = true;
        await this.$store.dispatch("OfficeProfiles/upsertPracticeOfficeRates", {
          rates: this.officeRates.map(officeRate => ({
            positionId: officeRate.positionId,
            practiceOfficeId: officeRate.practiceOfficeId,
            amount: officeRate.rate
          })),
          practiceOfficeId: this.selectedOfficeProfile.practiceOfficeId
        });
        this.loading = false;
      }
    });
  }

  async back() {
    this.$emit('update:activeTab', 3);
  }

  async next(): Promise<void> {
    this.$validator.validateAll().then(async (isValid: boolean) => {
      if (isValid) {
        this.selectedOfficeProfile.payrates = [ ...this.officeRates ];
        this.$emit('update:activeTab', 5);
      }
    });
  }
}
