import httpAuth from "@/common/http/http-common";

class DentalTempsService {
  async getPracticeEmployees(practiceOfficeId: number) {
    const { data } = await httpAuth().get(
      `employee/office/${practiceOfficeId}/all`
    );
    return data;
  }

  async getAllEmployees() {
    const { data } = await httpAuth().get(
      `employee/all`
    );
    return data;
  }
}

export default new DentalTempsService();