import { getField, updateField } from "vuex-map-fields";
import { VuexModule, Module, Action } from "vuex-module-decorators";
import DentalTempsService from "./dental-temps.service";
import { 
  PracticeEmployee
} from "./dental-temps.types";

@Module({ namespaced: true })
class DentalTemps extends VuexModule {
  employees: PracticeEmployee[] = [];


  @Action({ rawError: true })
  async fetchAllEmplyees() {
    const employees = await DentalTempsService.getAllEmployees() as PracticeEmployee[];
    
    this.context.commit("updateField", {
      path: "employees",
      value: employees
    });
  }

  @Action({ rawError: true })
  async fetchPracticeOfficeEmplyees(practiceOfficeId: number) {
    const employees = await DentalTempsService.getPracticeEmployees(
      practiceOfficeId
    ) as PracticeEmployee[];
    
    var employeeMap = employees.reduce(function(map, obj) {
      map.set(obj.id, obj);
      return map;
    }, new Map());

    for (var employee of this.employees) {
      var e = employeeMap.get(employee.id)
      if (e) {
        employee.isFavorite = e.isFavorite;
        employee.rating = e.rating;
      } else {
        employee.isFavorite = false;
        employee.rating = 0;
      }
    }
  }
}

DentalTemps.getters = { getField };
DentalTemps.mutations = { updateField };

export default DentalTemps;