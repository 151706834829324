import { RouteConfig } from "vue-router";
import LoginComponent from "./login.vue";
import RegisterComponent from "./register.vue";
import ResetPasswordComponent from "./reset-password.vue";
import NewPasswordComponent from "./new-password.vue";

export const AUTHENTICATE_ROUTES: RouteConfig[] = [
  {
    path: "/login",
    name: "Login",
    component: LoginComponent
  },
  {
    path: "/register",
    name: "Register",
    component: RegisterComponent
  },
  {
    path: "/resetpassword",
    name: "ResetPassword",
    component: ResetPasswordComponent
  },
  {
    path: "/newpassword/:email/:token",
    name: "NewPassword",
    component: NewPasswordComponent
  },
];