import { getField, updateField } from "vuex-map-fields";
import { VuexModule, Module, Action } from "vuex-module-decorators";
import WizardService from "./wizard.service";
import {
  WizardStep,
  InformationFormDto,
  SchedulerFormDto,
  HourFormDto,
  IRateFormDto,
  PayrollFormDto,
  IWizardFormDto
} from "./wizard.types";

import { Position } from "@/views/postings/postings.types";
import { AxiosError } from "axios";

@Module({ namespaced: true })
class Wizard extends VuexModule {
  errorMessage = "";
  isLoading = false;
  currentStep = WizardStep.Welcome;
  infoForm: InformationFormDto = new InformationFormDto();
  schedulerForm: SchedulerFormDto | null = new SchedulerFormDto();
  rateForms: IRateFormDto[] = [];
  payrollForm: PayrollFormDto = new PayrollFormDto();
  hourForms: HourFormDto[] = [
    { day: "Monday", break: "0", start: "08:00", end: "17:00" },
    { day: "Tuesday", break: "0", start: "08:00", end: "17:00" },
    { day: "Wednesday", break: "0", start: "08:00", end: "17:00" },
    { day: "Thursday", break: "0", start: "08:00", end: "17:00" },
    { day: "Friday", break: "0", start: "08:00", end: "17:00" },
    { day: "Saturday", break: "", start: "", end: "" },
    { day: "Sunday", break: "", start: "", end: "" }
  ];

  @Action({ rawError: true })
  async fetchPositions(): Promise<Position[]> {
    try {
      return await WizardService.getPositions();
    } catch (e) {
      console.error(e);
    }
    return [];
  }

  @Action({ rawError: true })
  async validateUsername(username: string): Promise<void> {
    try {
      this.context.commit("updateField", {
        path: "errorMessage",
        value: null
      });
      return await WizardService.validateUsername(username)
    } catch (e: unknown) {
      const errorMessage = (e as AxiosError).response?.data.message;
      this.context.commit("updateField", {
        path: "errorMessage",
        value: errorMessage
      });
    }
  }

  @Action({ rawError: true })
  async finish(): Promise<boolean> {
    try {
      const hours = this.hourForms.map(hour => ({
        day: hour.day ? hour.day : null,
        start: hour.start ? hour.start : null,
        end: hour.end ? hour.end : null,
        break: hour.break ? hour.break : null
      } as HourFormDto));

      const form: IWizardFormDto = {
        information: this.infoForm,
        scheduler: this.schedulerForm,
        hours: hours,
        rates: this.rateForms,
        payroll: this.payrollForm
      }

      await WizardService.finish(form);
      return true;
    } catch (e: unknown) {
      console.error(e);
      return false;
    }
  }
}

Wizard.getters = { getField };
Wizard.mutations = { updateField };

export default Wizard;