import httpAuth from "@/common/http/http-common";
import { 
  UpsertPracticeOffice,
  UpsertPracticeOfficeDefaultRate,
  UpsertPracticeOfficeHours,
  PracticeOfficeUserStatusDto,
  PracticeOfficeStatusDto,
  AddNewUserDto,
  PracticeDetails,
  UpsertPracticeOfficeRatesRequest,
  OfficePayrollForm
} from "./office-profiles.types";

class OfficeProfilesService {
  async getOfficeProfiles(practiceId: number) {
    const { data } = await httpAuth().get(`office-profile/practice/${practiceId}`);

    return data;
  }

  async getPracticeDetails(practiceId: number) {
    const { data } = await httpAuth().get(`office-profile/practice/${practiceId}/details`);

    return data;
  }

  async getOfficeUsers(practiceId: number) {
    const { data } = await httpAuth().get(`office-profile/practice/${practiceId}/user/all`);

    return data;
  }

  async upsertPracticeOffice(model: UpsertPracticeOffice) {
    const { data } = await httpAuth().put(`office-profile/practice-office`, model);

    return data;
  }

  async upsertPracticeOfficeDefaultRate(model: UpsertPracticeOfficeDefaultRate) {
    const { data } = await httpAuth().put(`office-profile/practice-office/rate`, model);

    return data;
  }

  async UpsertPracticeOfficeRates(model: UpsertPracticeOfficeRatesRequest) {
    const { data } = await httpAuth().put(`office-profile/practice-office/rates`, model);

    return data;
  }

  async savePayroll(model: OfficePayrollForm) {
    const { data } = await httpAuth().put('office-profile/payroll', model);

    return data;
  }

  async upsertPracticeOfficeHours(model: UpsertPracticeOfficeHours) {
    const { data } = await httpAuth().put(`office-profile/practice-office/hours`, model);

    return data;
  }

  async setPracticeOfficeStatus(model: PracticeOfficeStatusDto) {
    const { data } = await httpAuth().put(`office-profile/practice-office/status`, model);

    return data;
  }

  async upsertUserToPracticeOffice(model: PracticeOfficeUserStatusDto) {
    const { data } = await httpAuth().put(`office-profile/practice-office/user`, model);

    return data;
  }

  async addNewUser(model: AddNewUserDto) {
    const { data } = await httpAuth().put(`office-profile/user`, model);

    return data;
  }

  async getPositions() {
    const { data } = await httpAuth().get(
      `Postings/GetPositions`
    );
    return data;
  }

  async updatePractice(model: PracticeDetails) {
    const { data } = await httpAuth().put(`office-profile/practice/name`, model);

    return data;
  }
}

export default new OfficeProfilesService();