import httpAuth from "@/common/http/http-common";
import { 
  NotificationStatusDto,
  IPracticeOfficeNotificationState
} from "./notifications.types";

class NotificationsService {
  async getOfficeNotifications(userId: number) {
    const { data } = await httpAuth().get(`notification/practice-office/user/${userId}`);

    return data;
  }

  async getOfficeNotificationState(userId: number, fromId: number): Promise<IPracticeOfficeNotificationState> {
    const { data } = await httpAuth().get(`notification/practice-office/state/${fromId}/user/${userId}`);
    
    return data;
  }

  async setPracticeOfficeNotificationStatus(notificationStatusDto: NotificationStatusDto) {
    const { data } = await httpAuth().post(
      `notification/practice-office/status`,
      notificationStatusDto
    );
    
    return data;
  }
}

export default new NotificationsService();