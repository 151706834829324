
import { Component, Mixins } from "vue-property-decorator";
import { ErrorBag, Field } from "vee-validate";
import { OfficeProfileHours } from "./office-profiles.types";
import OfficeProfilesMixin from "./office-profiles.mixin";

@Component
export default class OfficeHoursEditComponent extends Mixins(OfficeProfilesMixin) {
  officeHours: OfficeProfileHours[] = [];
  loading = false;
  veeFields!: Field;
  veeErrors!: ErrorBag;

  initial = [
    {
      day: "Monday",
      break: "0",
      start: "08:00",
      end: "17:00"
    },
    {
      day: "Tuesday",
      break: "0",
      start: "08:00",
      end: "17:00"
    },
    {
      day: "Wednesday",
      break: "0",
      start: "08:00",
      end: "17:00"
    },
    {
      day: "Thursday",
      break: "0",
      start: "08:00",
      end: "17:00"
    },
    {
      day: "Friday",
      break: "0",
      start: "08:00",
      end: "17:00"
    },
    {
      day: "Saturday",
      break: "",
      start: "",
      end: ""
    },
    {
      day: "Sunday",
      break: "",
      start: "",
      end: ""
    }
  ];

  mounted() {
    this.officeHours = [ ... this.isEdit ? this.selectedOfficeProfile.hours : this.initial ];
    this.$validator.reset();
  }

  validateState(ref: keyof Field) {
    if (
      this.veeFields[ref] &&
      (this.veeFields[ref].dirty || 
      this.veeFields[ref].validated)
    ) {
      return !this.veeErrors.has(ref);
    }
    return null;
  }

  async update() {
    this.$validator.validateAll().then(async (isValid: boolean) => {
      if (isValid) {
        this.loading = true;
        await this.$store.dispatch("OfficeProfiles/upsertPracticeOfficeHours", {
          practiceOfficeId: this.selectedOfficeProfile.practiceOfficeId,
          sundayOpenTime: this.getOpenTime("Sunday", this.officeHours),
          sundayCloseTime: this.getCloseTime("Sunday", this.officeHours),
          mondayOpenTime: this.getOpenTime("Monday", this.officeHours),
          mondayCloseTime: this.getCloseTime("Monday", this.officeHours),
          tuesdayOpenTime: this.getOpenTime("Tuesday", this.officeHours),
          tuesdayCloseTime: this.getCloseTime("Tuesday", this.officeHours),
          wednesdayOpenTime: this.getOpenTime("Wednesday", this.officeHours),
          wednesdayCloseTime: this.getCloseTime("Wednesday", this.officeHours),
          thursdayOpenTime: this.getOpenTime("Thursday", this.officeHours),
          thursdayCloseTime: this.getCloseTime("Thursday", this.officeHours),
          fridayOpenTime: this.getOpenTime("Friday", this.officeHours),
          fridayCloseTime: this.getCloseTime("Friday", this.officeHours),
          saturdayOpenTime: this.getOpenTime("Saturday", this.officeHours),
          saturdayCloseTime: this.getCloseTime("Saturday", this.officeHours),
          sundayBreakMinutes: this.getBreakMinutes("Sunday", this.officeHours),
          mondayBreakMinutes: this.getBreakMinutes("Monday", this.officeHours),
          tuesdayBreakMinutes: this.getBreakMinutes("Tuesday", this.officeHours),
          wednesdayBreakMinutes: this.getBreakMinutes("Wednesday", this.officeHours),
          thursdayBreakMinutes: this.getBreakMinutes("Thursday", this.officeHours),
          fridayBreakMinutes: this.getBreakMinutes("Friday", this.officeHours),
          saturdayBreakMinutes: this.getBreakMinutes("Saturday", this.officeHours)
        });
        this.loading = false;
      }
    });
  }

  async back() {
    this.$emit('update:activeTab', 2);
  }

  async next() {
    this.$validator.validateAll().then(async (isValid: boolean) => {
      if (isValid) {
        this.selectedOfficeProfile.hours = [ ...this.officeHours ];
        this.$emit('update:activeTab', 4);
      }
    });
  }
}
