
import { Component, Mixins, Prop } from "vue-property-decorator";
import { IVacancyDto, VacancyStatus } from "./vacancies-types";
import { getAddressString } from "@/utils/addressUtils";
import AuthenticateMixin from "../authenticate/authenticate.mixin";

@Component
export default class VacancyCardComponent extends Mixins(AuthenticateMixin) {
  @Prop() vacancy!: IVacancyDto;

  VacancyStatus = VacancyStatus;
  Math = Math;

  showApplicantModal(): void {
    this.$emit("applicantClick", this.vacancy.id);
  }

  onEdit(): void {
    this.$emit("edit", this.vacancy.id);
  }

  onDelete(): void {
    this.$emit("delete", this.vacancy.id);
  }

  onFill(): void {
    this.$emit("fill", this.vacancy.id);
  }

  onRepost(): void {
    this.$emit("repost", this.vacancy.id);
  }

  get address(): string {
    return getAddressString(this.vacancy);
  }

  get canEdit(): boolean {
    return this.vacancy.officeSchedulerIds.some(id => id === this.userInfo()?.sub);
  }
}
