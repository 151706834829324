import { generateHexColor } from "@/utils/colorUtils";
import { EmployeeType, IAddress, PayrollType } from "../office-profiles/office-profiles.types";

export class InformationFormDto implements IAddress {
  constructor() {
    this.color = generateHexColor();
  }

  practiceName = "";
  officeName = "";
  address1 = "";
  address2: string | null = null;
  city = "";
  state = "";
  zipCode = "";
  website = "";
  phone = "";
  email = "";
  color: string;
}

export class SchedulerFormDto {
  firstName = "";
  lastName = "";
  email = "";
  cellPhone = "";
}

export class HourFormDto {
  day = "";
  start = "";
  end = "";
  break = "";
}

export interface IRateFormDto {
  positionId: number;
  position: string;
  rate: number;
}


export class PayrollFormDto {
  employeeType?: EmployeeType;
  type?: PayrollType;
  description?: string;
}

export interface IWizardFormDto {
  information: InformationFormDto;
  scheduler: SchedulerFormDto | null;
  hours: HourFormDto[];
  rates: IRateFormDto[];
  payroll: PayrollFormDto;
}

export enum PracticeOfficeUserStatus {
  Active = 70,
  Inactive = 71
}

export enum WizardStep {
  Welcome = 1,
  Information,
  Schedulers,
  Hours,
  Rates,
  Payrolls,
  Complete
}