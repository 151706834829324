
import { Component, Mixins } from 'vue-property-decorator';
import { VacancyDetailsDto } from './vacancies-types';
import { getAddressString } from '@/utils/addressUtils';
import { format as formatPhone } from "@/utils/phoneUtils";
import { mapState } from 'vuex';
import { EmployeeKey } from '@/components/employee-details';
import { PracticeOfficeModel } from '../postings/postings.types';
import "vue2-editor/dist/vue2-editor.css";
import AuthenticateMixin from '../authenticate/authenticate.mixin';
import { EmployeeDetails } from "@/components/employee-details";

@Component({
  components: {
    EmployeeDetails
  },
  computed: {
    ...mapState("Vacancy", { details: "vacancyDetails" }),
    ...mapState("Postings", ["selectedOffice"])
  }
})
export default class VacancyApplicantComponent extends Mixins(AuthenticateMixin) {
  details!: VacancyDetailsDto;

  currentEmployee!: EmployeeKey;
  selectedOffice!: PracticeOfficeModel;
  isEmployeeDetailsVisible = false;

  fields = [
    'name',
    'email',
    { key: 'phoneNumber', label: 'Phone' },
    { key: 'isInterested', label: 'Interested?' }
  ];

  Math = Math;
  formatPhone = formatPhone;

  get address(): string {
    return getAddressString(this.details);
  }

  async getData(): Promise<void> {
    await this.$store.dispatch("Vacancy/getDetails",  { id: this.details.id, practiceId: this.userInfo()?.practiceId });
  }

  getFormattedDate(date: string): string {
    return new Date(date).toLocaleDateString();
  }

  async toggleInterest(userId: number, vacancyId: number, isInterested: boolean | null): Promise<void> {
    const applicant = this.details.applicants
      .find(applicant => applicant.vacancyId === vacancyId && applicant.userId === userId);

    if (applicant?.isInterested === isInterested) {
      isInterested = null;
    }

    await this.$store.dispatch("Vacancy/toggleInterest", { userId, vacancyId, isInterested });
    await this.$store.dispatch("Vacancy/getDetails",  { id: this.details.id, practiceId: this.userInfo()?.practiceId });
  }

  isFavoriteChanged(data: { isFavorite: boolean }): void {
    const applicant = this.details.applicants.find(applicant => applicant.userId == this.currentEmployee?.userId);
    if (applicant) {
      applicant.isFavorite = data.isFavorite;
    }
  }

  onClose(): void { 
    this.$store.dispatch("Vacancy/resetDetails");
  }

  closeModal(): void {
    this.$bvModal.hide('vacancy-applicant-modal');
  }

  showEmployeeDetailsModal(userId: number): void {
    this.currentEmployee = { userId: userId, practiceId: this.userInfo()?.practiceId ?? 0 };
    this.isEmployeeDetailsVisible = true;
  }
}
