
import { Component, Mixins } from "vue-property-decorator";
import Header from "./header.vue";
import Navigation from "./navigation.vue";
import AuthenticateMixin from "../authenticate/authenticate.mixin";

@Component({
  components: {
    Header,
    Navigation
  }
})
export default class BaseComponent extends Mixins(AuthenticateMixin) {}
