
import { Component, Prop, Mixins } from "vue-property-decorator";

import {  
  PracticeEmployee,
} from "./dental-temps.types";
import AuthenticateMixin from "../authenticate/authenticate.mixin";

@Component
export default class EmployeeCardComponent extends Mixins(AuthenticateMixin) {
  @Prop() employee!: PracticeEmployee;
  @Prop({default: false}) showRating!: boolean;

  showDetails(employeeId: number): void {
    this.$emit("details", employeeId);
  }

  positionDescription(positions: string[]): string {
    if (!positions || positions.length == 0)
    return "";
    return positions.length == 1 
    ? positions[0]
    : `${positions[0]}, +${positions.length-1}`
  }

  positionTitle(positions: string[]): string {
    if (!positions || positions.length == 0)
      return "";
    return positions.join(", ");
  }
}
