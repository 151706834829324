import { RouteConfig } from "vue-router";
import ContactsComponent from "@/views/contacts/contacts.vue";
import { Roles } from "../authenticate/authenticate.types";

export const CONTACTS_ROUTES: RouteConfig[] = [
  {
    path: "/contacts",
    name: "Contacts",
    component: ContactsComponent,
    meta: {
      roles: [Roles.PracticeUser, Roles.OfficeUser],
      nav: true,
      navTitle: "Contact Us",
      navIcon: "fa-at"
    }
  }
];