
import { Component, Prop, Mixins } from "vue-property-decorator";
import { 
  AcceptedJobPost, 
  EmployeeDetailsModel,
  JobPostStatus, 
  PracticeOfficeModel
} from "../postings.types";
import HorizontalTrackingComponent from "./components/horizontal-tracking.vue";
import VerticalTrackingComponent from "./components/vertical-tracking.vue";
import EmployeeDetails from "../modals/employee-details.vue";
import PostingsMixin from "../postings.mixin";
import { mapState } from "vuex";
import { PayrollType } from "@/views/office-profiles/office-profiles.types";

@Component({
  components: {
    HorizontalTrackingComponent,
    VerticalTrackingComponent,
    EmployeeDetails
  },
  computed: {
    ...mapState("Postings", ["selectedOffice"])
  }
})
export default class ConfirmedCard extends Mixins(PostingsMixin) {
  @Prop() confirmedJob!: AcceptedJobPost;

  employeeDetails: EmployeeDetailsModel = new EmployeeDetailsModel;
  JobPostStatus = JobPostStatus;
  showHistory = false;
  modalShow = false;
  selectedOffice!: PracticeOfficeModel;

  getClasses(val: boolean): string[] {
    return val ? ['fas', 'fa-check-circle'] : ['far', 'fa-circle'];
  }

  get details(): string {
    return [
      this.confirmedJob.date.toLocaleDateString("en-US", {
        weekday: 'long',
        month: 'numeric',
        year: 'numeric',
        day: 'numeric'
      }), 
      this.confirmedJob.position,
      this.confirmedJob.time,
      this.confirmedJob.rate
    ].join(' · ')
  }

  get eodValue(): boolean {
    return this.selectedOffice.payroll?.type === PayrollType.EndOfDay;
  }

  getEmployeeDetails(): void {
    this.employeeDetails = {
      name: this.confirmedJob.name,
      rating: this.confirmedJob.rating,
      phone: this.confirmedJob.phone,
      email: this.confirmedJob.email,
      date: new Date(this.confirmedJob.date),
      position: this.confirmedJob.position,
      rate: this.confirmedJob.rate,
      timeIn: this.getTimes(this.confirmedJob.time, 0, " - "),
      timeOut: this.getTimes(this.confirmedJob.time, 1, " - "),
      initials: this.getInitials(this.confirmedJob.name),
      isFavorite: this.confirmedJob.isFavorite,
      id: this.confirmedJob.userId
    };
    this.modalShow = true;
  }

  getInitials (name: string): string {
    const names = name.split(' ')
    return `${names[0].charAt(0)}${names[1] ? names[1].charAt(0) : ''}`
  }
}
