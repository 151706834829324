import { getField, updateField } from "vuex-map-fields";
import { VuexModule, Module, Action } from "vuex-module-decorators";
import RatingsService from "./ratings.service";
import { 
  RatingsModel,
  RatingsStatus,
  UpdateJobPostRatingDto,
  RatingItemsModel
} from "./ratings.types";
import moment from "moment";

@Module({ namespaced: true })
class Ratings extends VuexModule {
  newRatings: RatingItemsModel[] = [];
  oldRatings: RatingItemsModel[] = [];

  @Action({ rawError: true })
  async fetchPracticeOfficeRatings(practiceOfficeId: number) {
    const ratings = await RatingsService.getPracticeRatingsOfDt(
      practiceOfficeId
    ) as RatingsModel[];
    const openRatings = ratings.filter(rating => rating.status == RatingsStatus.Open);
    const nonOpenRatings = ratings.filter(rating => rating.status != RatingsStatus.Open);
    const newRatings: RatingItemsModel[] = [];
    const oldRatings: RatingItemsModel[] = [];
    for(const openRating of openRatings) {
      const member = `${openRating.dtFirstName} ${openRating.dtLastName}`;
      const date = moment(openRating.startDateOnly).format("MM/DD/YYYY");

      newRatings.push({
        comments: openRating.comments,
        date: date,
        member: member,
        id: openRating.jobPostRatingByPracticeOfficeId,
        office: openRating.officeName,
        rating: openRating.rating,
        userId: openRating.userId,
        noShow: openRating.noShow,
        log: '',
        disabled: false
      });
    }
    this.context.commit("updateField", {
      path: "newRatings",
      value: newRatings
    });
    for(const nonOpenRating of nonOpenRatings) {
      const member = `${nonOpenRating.dtFirstName} ${nonOpenRating.dtLastName}`;
      const ratedBy = `${nonOpenRating.raterFirstName} ${nonOpenRating.raterLastName}`;
      const modified = moment(nonOpenRating.lastModifiedDate).format("MM/DD/YYYY hh:mm A");
      const date = moment(nonOpenRating.startDateOnly).format("MM/DD/YYYY");
      const log = `Rated by ${ratedBy}, ${modified}`;

      oldRatings.push({
        comments: nonOpenRating.comments,
        date: date,
        member: member,
        id: nonOpenRating.jobPostRatingByPracticeOfficeId,
        office: nonOpenRating.officeName,
        rating: nonOpenRating.rating,
        disabled: true,
        log: log,
        userId: nonOpenRating.userId,
        noShow: nonOpenRating.noShow
      });
    }
    this.context.commit("updateField", {
      path: "oldRatings",
      value: oldRatings.sort((a, b) => b.date.localeCompare(a.date))
    });
  }

  @Action({ rawError: true })
  async updateJobPostRating(updateJobPostRatingDto: UpdateJobPostRatingDto) {
    try {
      await RatingsService.updateJobPostRating(updateJobPostRatingDto);
    } catch (e) {
      console.error(e);
    }
  }
}

Ratings.getters = { getField };
Ratings.mutations = { updateField };

export default Ratings;