import httpAuth from "@/common/http/http-common";
import { 
  UpdateJobPostRatingDto
} from "./ratings.types";

class RatingsService {
  async getPracticeRatingsOfDt(practiceId: number) {
    const { data } = await httpAuth().get(
      `Ratings/GetPracticeRatingsOfDt?practiceId=${practiceId}`
    );
    return data;
  }

  async updateJobPostRating(updateJobPostRatingDto: UpdateJobPostRatingDto) {
    const { data } = await httpAuth().post(
      `Ratings/UpdateJobPostRating`,
      updateJobPostRatingDto
    );
    return data;
  }
}

export default new RatingsService();