
import { Component, Vue, Prop } from "vue-property-decorator";
import SchedulersEditUser from "./schedulers-edit-user.vue";
import { PracticeUser } from "./schedulers.types";

@Component({
  components: {
    SchedulersEditUser
  }
})
export default class SchedulersCardComponent extends Vue {
  @Prop() practiceUser!: PracticeUser;

  modalShow = false;

  async editProfile() {
    this.$store.commit("Schedulers/updateField", {
      path: "selectedUser",
      value: this.practiceUser
    });
    this.modalShow = true;
  }

  reload() {
    this.$emit('reload');
  }
}
