
import { Component, Vue, Prop } from "vue-property-decorator";
import moment from "moment";
import { 
  PracticeOfficeNotification,
  NotificationType
} from "./notifications.types";

const basePath = require('./../../assets/notification-icons.svg')

@Component
export default class NotificationsCardComponent extends Vue {
  @Prop() notification!: PracticeOfficeNotification;

  get notificationDetails() {

    switch(+this.notification.notificationTypeId) {
      case NotificationType.Immediate:
        return  {
          //icon: "fa-clock",
          icon: basePath+"#icon-requested",
          description: "Bad News. Unfortunately, time has run out for one of your dental temps to confirm a shift. We have placed this shift back over into the Requests column.  Please navigate back to the Jobs Board to review your options. In the meantime, we have already reposted this shift out the our team, and hope to have some additional candidates to offer you soon.",
          title: "Timeout",
          cssClass: "timeout"
        }
      case NotificationType.Information:
        return {
          //icon: "fa-calendar-check",
          icon: basePath+"#icon-confirmed",
          description: "High Five!! Your TeamedUp Coach is excited to announce that one of your shifts has been confirmed.",
          title: "Confirmed",
          cssClass: "confirmed"
        }
      case NotificationType.Pending:
        return {
          //icon: "fa-hourglass-half",
          icon: basePath+"#icon-pending",
          description: "Awesome news! A TeamedUp member wants to take one of your posted shifts. Could you confirm or decline their request soon? This will allow them to either secure it in their schedule or start searching for another shift if needed. Thanks for your prompt response!",
          title: "Requested",
          cssClass: "requested"
        };
      case NotificationType.Cancelled:
        return {
          icon: basePath+"#icon-cancelled",
          description: "Oh no! Looks like someone just canceled their shift request. But don’t worry—there might already be someone else who's picked it up. You can check the Jobs Board to see for sure. If not, no stress, we're already on the lookout for someone new to take over. We'll keep you posted as soon as we have someone lined up!",
          title: "Cancelled",
          cssClass: "cancelled"
        }
      default:
        return {
            //icon: "fa-star",
            icon: basePath+"#icon-open",
            description: "We noticed you have some shifts that have not been rated. Rating shifts is a great way to remind yourself how well someone worked with your team. To provide feedback on your unrated shifts, click on the Star icon in your navigation bar.",
            title: "Ratings",
            cssClass: "ratings"
          };
    }
  }

  get notificationHeader(): string {
    if (this.notification.notificationTypeId == NotificationType.Rating) 
      return "We would love to get your opinion.";
    
    var date = moment(this.notification.shiftDate).format("MM/DD/YYYY");

    let text = this.notification.officeName;

    if (this.notification.relatedEmployee){
      text += `: ${this.notification.relatedEmployee} - ${date}, ${this.notification.positionName}`;
    }
    else {
      text += `: ${date}, ${this.notification.positionName}`;
    }

    return text;
  }

  async onDelete() {
    this.$emit("delete", this.notification);
  }
}
