export class PracticeOfficeNotification {
  practiceOfficeNotificationId: number = 0;
  notificationMessage: string = "";
  practiceOfficeId: number = 0;
  notificationStatus: number = 0;
  notificationTypeId: number = 0;
  jobPostGroupId: number = 0;
  notificationTitle: string = "";
  notificationColor: string = "";
  officeName: string = "";
  notificationDate: Date = new Date();
  relatedEmployee: string = "";
  shiftDate: Date = new Date();
  positionName: string = "";
}

export interface IPracticeOfficeNotificationState {
  lastId: number;
  notificationCount: number;
}

export class NotificationStatusDto {
  practiceOfficeNotificationId: number = 0;
  status: number = 0;
}

export enum NotificationType {
  Information = 4, // Confirmed
  Immediate = 6, // Timeout
  Pending = 1,
  Rating = 13,
  Cancelled = 14
}

export enum NotificationStatus {
  Active = 70,
  Inactive = 71
}