
import { Component, Vue } from "vue-property-decorator";
import { mapState } from "vuex";
import { WizardStep } from "./wizard.types";

@Component({
  computed: {
    ...mapState('Wizard', ['currentStep'])
  }
})
export default class Header extends Vue {
  currentStep!: WizardStep;
  WizardStep = WizardStep;

  get stepCount(): number {
    return Object.keys(WizardStep).length / 2 - 2;
  }
}
