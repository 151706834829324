
import { Component, Prop, Vue } from "vue-property-decorator";
import { ISelectOption } from "./vacancies-types";

@Component
export default class VacancyCheckbox extends Vue {
  @Prop() value!: number[] | number;
  @Prop() options!: ISelectOption[];
  @Prop() title!: string;
  @Prop() multiple!: boolean;
  @Prop({ default: true }) extraLabel!: boolean;
  @Prop({ default: true }) customAction!: boolean;

  isOptionActive(option: ISelectOption): boolean {
    return this.value === option.value ||
      (Array.isArray(this.value) && this.value.some(val => val === option.value));
  }

  onClick(option: ISelectOption): void {
    let value;
    
    if (Array.isArray(this.value)){
      if (this.value.some(value => value === option.value)) {
        value = this.value.filter(value => value !== option.value);
      }
      else {
        value = [ ...this.value, option.value ];
      }
    }
    else {
      if (this.value === option.value) {
        value = null;
      }
      else {
        value = option.value;
      }
    }

    this.$emit("input", value);
  }
}
