
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ActionButton extends Vue {
  @Prop({ default: false }) disabled!: boolean;
  @Prop() icon!: string;

  onClick(): void {
    this.$emit('click');
  }
}
