import { Component, Vue } from "vue-property-decorator";

@Component
export default class TableMixin extends Vue {
  sortByInternal: string | null = 'date';
  sortDescInternal = false;

  filterCallback(rowData: Record<string, unknown>, filters: Record<string, unknown>): boolean | undefined {
    const filterKeys = Object.keys(filters).filter(key => isNaN(Number(key)));
    let notFiltered = true;

    filterKeys.forEach(key => {
      if (!notFiltered) {
        return;
      }

      if ((filters[key] as unknown[]).length) {
        notFiltered = (filters[key] as unknown[]).some(value => value === rowData[key]);
      }
    });

    return notFiltered;
  }
}