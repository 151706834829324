import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import AuthService from "@/services/AuthService";
import { LoginModel, RegisterModel } from "@/models";
import store from "../../store"

const storedUser = localStorage.getItem("user");

@Module({ namespaced: true })
class Auth extends VuexModule {
  status = storedUser ? { loggedIn: true } : { loggedIn: false };
  user = storedUser ? JSON.parse(storedUser) : null;
  failureMessage = "";

  @Mutation
  failure(error: string): void {
    this.failureMessage = error;
  }
  @Mutation
  loginSuccess(): void {
    this.status.loggedIn = true;
    const newUser = localStorage.getItem("user");
    this.user = newUser ? JSON.parse(newUser) : null;
  }

  @Mutation
  loginFailure(): void {
    this.status.loggedIn = false;
    this.user = null;
  }

  @Mutation
  reset(): void {
    this.status.loggedIn = false;
    this.user = null;
    this.failureMessage = "";
  }

  @Mutation
  registerSuccess(): void {
    this.status.loggedIn = false;
  }

  @Mutation
  registerFailure(): void {
    this.status.loggedIn = false;
  }

  @Action({ rawError: true })
  async login(data: LoginModel) {
    this.context.commit("failure", "");
    try {
      const response = await AuthService.login(data.username, data.password);
      console.log("loginSuccess", response);
      this.context.commit("loginSuccess");
      return response;
    } catch (error) {
        const message =
        ((error as any).response &&
         (error as any).response.data &&
         (error as any).response.data.message) ||
        (error as any).message ||
        (error as any).toString();
      console.log("error login: ", message);
      this.context.commit("failure", message);
    }
  }

  @Action({ rawError: true })
  async register(data: RegisterModel) {
    this.context.commit("failure", "");
    try {
      const response = await AuthService.register(
        data.firstName, 
        data.lastName,
        data.email,
        data.password,
        data.confirm
      );
      console.log("registerSuccess", response);
      this.context.commit("loginSuccess");
      return response;
    } catch (error) {
      const message =
        ((error as any).response &&
         (error as any).response.data &&
         (error as any).response.data.message) ||
        (error as any).message ||
        (error as any).toString();
      console.log("error register: ", message);
      this.context.commit("failure", message);
    }
  }

  @Action({ rawError: true })
  resetModule() {
    console.log("resetting Auth module");
    this.context.commit("reset");
  }

  @Action({ rawError: true })
  signOut(): void {
    AuthService.logout();
    (store as any).dispatch("resetModules");
  }

  get isLoggedIn(): boolean {
    return this.status.loggedIn;
  }
  get currentUser(): LoginModel {
    return this.user;
  }
  get errorMessage(): string {
    return this.failureMessage;
  }
}

export default Auth;
